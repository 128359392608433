<template>
  <li>
    <p class="tit">
      <a target="_blank" @click="goNew(detail)">{{ detail.title }}</a>
    </p>
    <p class="content">
      {{ detail.newAbstract }}
    </p>
    <div class="flex-btwn">
      <span class="date">{{ detail.createTime }}</span>
      <p>
        <span class="mr-40 txt">{{ detail.original_title }}</span>
        <!-- <span class="mr-40 txt">{{ detail.link }}</span
        > -->
        <span class="mr-40 txt">{{ detail.create_time }}</span
        ><a target="_blank" @click="goNew(detail)"
          ><span class="f-link">了解更多></span></a
        >
      </p>
    </div>
  </li>
</template>
<script>
export default {
  props: ['detail'],
  methods: {
    goNew (item) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          type: item.type,
          pageId: item.id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
li {
  padding: 25px 0;
  list-style: none;
  position: relative;
  padding-left: 30px;
  &:hover {
    .tit {
      color: #336fd0;
    }
  }
}
li::before {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  background-color: #d2d2d2;
  position: absolute;
  left: 0;
  top: 40px;
}
.tit {
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  line-height: 2;
}
.content {
  font-size: 14px;
  color: #555;
  line-height: 2;
}
.date,
.txt {
  font-size: 14px;
  color: #555;
}
.f-link {
  font-size: 14px;
  color: #336fd0;
}
// 新闻标题的颜色和鼠标悬浮时标题的颜色
a {
  color: black;
}
a:hover {
  color: #336fd0 !important;
}
</style>
