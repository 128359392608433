<template>
  <div>
    <div class="mainWarpPadd50">
      <div class="title">
        <img
          src="http://www.pekhongyuan.com/pic/hongyuan/images/title-bussiness3.png"
          alt="跨境电商物流"
        />
      </div>
      <div class="instructions mb-100">
        <p>
          宏远跨境电商业务始于2016年，公司秉承“以客户为中心”的企业精神，坚持全流程标准化操作，并在几年中不断自主研发多套信息系统，既有可为小微电商提供的公众服务系统，也可与大中型电商进行系统对接，满足不同层级客户的多样化业务需求，适用仓配、转运、集运、闪购、干线、申报、通关、派送等多模式跨境电商物流的各环节。同时，宏远多年来积极自建标准化海外仓，希望在后疫情时代依托首都国际机场丰富的航线资源与全球120个国家、154个城市相连，并利用北京枢纽优势高效覆盖中国全境。
        </p>
      </div>
    </div>
    <div class="bgImgRow">
      <div class="row-main">
        <dl class="flex-btwn row-r">
          <dt>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness41.jpg"
            />
          </dt>
          <dd>
            <br />
            <p class="f1">
              跨境电商通关服务
            </p>
            <ul>
              <li>
                宏远于北京首都国际机场建设运营1.2万平方米跨境电商通关库区，采用环形分拣线与自主研发系统结合，每小时可处理过万件电商包裹。
              </li>
              <li>
                创新实践“机下直提、站坪直通，库内直转、全年无休，全态覆盖”的一站式高效服务模式，持续服务如京东、豌豆公主、唯品会、考拉海购等数十家主流跨境电商。
              </li>
              <li>
                宏远于南昌昌北国际机场正在建设的跨境电商通关库区，通过全面使用AGV设备配合AI智能系统将在业内率先实现电商包裹通关操作、监管、防控的全智能化，致力于打造高效的电商包裹无感通关环境。
              </li>
            </ul>
          </dd>
        </dl>
      </div>
    </div>
    <div class="bgImgRow">
      <div class="row-main">
        <dl class="flex-btwn row-l">
          <dd>
            <p class="f1">
              航空运输关务服务
            </p>
            <ul>
              <li>
                集聚多家AEO高级认证企业，依托于自主开发的智能归类系统和关务辅助系统实现报关智能化，流程可视化。为各类客户提供多模式进出口报关服务以及包机关务代理服务。
              </li>
            </ul>
          </dd>
          <dt>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness42.jpg"
            />
          </dt>
        </dl>
      </div>
    </div>
    <div class="bgImgRow">
      <div class="row-main">
        <dl class="flex-btwn row-r">
          <dt>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness43.jpg"
            />
          </dt>
          <dd>
            <br />
            <p class="f1">
              跨境电商全程物流
            </p>
            <ul>
              <li>
                宏远旗下企业分别获批各国国际快递业务资质，整合集团海外仓储、干线运输、通关服务等资源，多年来为京东、菜鸟等数十家主流电商客户提供多模式的电商货物全链路物流服务。
              </li>
            </ul>
          </dd>
        </dl>
      </div>
    </div>
    <div class="bgImgRow">
      <div class="row-main">
        <dl class="flex-btwn row-l">
          <dd>
            <p class="f1">
              电商海外仓储服务
            </p>
            <ul>
              <li>
                宏远于亚洲、欧洲、美洲和大洋洲陆续设立多个海外仓，服务区域覆盖日韩、美国、欧洲、澳洲、中国香港、中国台湾等地区。凭借宏远多年业务经验积累，现今，以全AGV设备运营并兼具保税功能的，全面实现操作智能化、，管控智慧化的宏远“C时代”智能海外仓，正助力百余家电商客户提升其物流服务质量和效率。
              </li>
            </ul>
          </dd>
          <dt>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness44.jpg"
            />
          </dt>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      clientWidth: document.documentElement.clientWidth
    }
  }
}
</script>
<style lang="less" scoped>
p,
dl {
  margin-bottom: 0;
}
.title {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}
.instructions {
  font-size: 14px;
  color: #555;
  line-height: 2;
  p {
    margin-bottom: 70px;
  }
}
dl {
  align-items: center;
  dt {
    width: 42%;
  }
  img {
    width: 100%;
  }
  dd {
    width: 62.7%;
    font-size: 14px;
    color: #555;
    line-height: 1.8;
    box-sizing: border-box;
    p + p {
      margin-top: 30px;
    }
    .f1 {
      font-size: 16px;
      color: #333;
      padding-bottom: 30px;
    }
    li {
      padding-bottom: 20px;
      padding-left: 30px;
      line-height: 1.8;
      list-style: none;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 10px;
        background-color: #d2d2d2;
        position: absolute;
        left: 0;
        top: 15px;
      }
    }
  }
}
.row-r dd {
  padding-left: 40px;
}
.row-l dd {
  padding-right: 40px;
}
.li-title {
  padding-top: 90px;
  margin-bottom: 30px;
  font-size: 30px;
  color: #555;
}
@media screen and (max-width: 900px) {
  .mainWarpPadd50 {
    padding: 0 15px;
  }
  .title {
    overflow: hidden;
  }
  .title img {
    width: 100%;
    transform: scale(2.4);
  }
  .bgImgRow .row-main {
    padding: 0 15px;
    display: block;
  }
  .bgImgRow {
    height: auto;
    padding-bottom: 15px;
  }
  .row-r dd {
    padding: 0;
  }
  dl dd {
    width: auto;
  }
  .row-main img {
    width: 100%;
  }
  li .f1 {
    margin-bottom: 0;
  }
  .f-title {
    display: flex;
    align-items: center;
    line-height: 100%;
  }
  .flex-btwn {
    display: block;
    padding: 15px 0;
  }
  dl dt {
    width: auto;
  }
  dl dd li {
    padding-bottom: 20px;
    padding-left: 30px;
    line-height: 1.8;
    list-style: none;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: #d2d2d2;
      position: absolute;
      left: 0;
      top: 15px;
    }
  }
}
</style>
