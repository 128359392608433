<template>
  <div>
    <MainHeader />
    <MainBanner
      :src="bannerSrc"
      :TopTitle="TopTitle"
      :secondTitle="secondTitle"
    />
    <Menus
      :menus="menus"
      class="fix-menus"
      :current="currentType"
      @select="handleSelect"
    />
    <Article />
    <MainFooter footer="" />
  </div>
</template>
<script>
import MainHeader from '../public/header'
import Menus from '../public/h-menus'
import MainBanner from '../public/simpleBanner'
import MainFooter from '../public/footer'
import Article from './components/article'
import { data as menus } from './menus.json'
// import bannerImg from 'http://www.pekhongyuan.com/pic/hongyuan/images/banner-news.jpg'

export default {
  data () {
    return {
      bannerSrc:
        'http://www.pekhongyuan.com/pic/hongyuan/images/banner-news.jpg',
      menus, // 菜单json
      TopTitle: '新闻资讯',
      currentType: '1',
      secondTitle: 'NEWS'
    }
  },
  watch: {
    '$route.params': {
      handler (params) {
        console.log('watch')
        if (params.type) {
          this.currentType = params.type
        }
      },
      deep: true
    }
  },
  methods: {
    handleSelect (v) {
      if (v === this.current) return
      this.$router.push({
        name: 'News',
        params: { type: v }
      })
    }
  },
  components: {
    MainHeader,
    Menus,
    MainBanner,
    MainFooter,
    Article
  }
}
</script>
<style lang="less" scoped>
.main {
  min-width: 1200px;
}
.fix-menus {
  margin-top: -90px;
  z-index: 100;
  position: relative;
}
@media screen and(max-width:900px) {
  .fix-menus {
    margin-top: -60px;
    z-index: 100;
    position: relative;
  }
}
</style>
