<template>
  <div class="act-item">
    <div class="act-item-img">
      <img :src="act.src" />
    </div>
    <div class="act-item-tit">
      <div class="tit">{{ act.title }}</div>
      <div class="detail">{{ act.detail }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    act: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style lang="less" scoped>
  .act-item {
    padding: 0 20px;
    margin-bottom: 40px;
    width: 50%;
    &-img {
      img {
        width: 100%;
      }
    }
    &-tit {
      .tit {
        font-weight: bold;
        color: #000;
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      .detail {
        line-height: 1.5;
      }
    }
  }
</style>
