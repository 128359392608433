<template>
  <div class="steps-box">
    <div :class="leftClass">
      <ScrollView >
        <template slot-scope="visibility">
          <Left :visible="visibility[`left${index}`]" :key="`left${index}`" :text="text" />
        </template>
      </ScrollView>
    </div>
    <div :class="rightClass">
      <ScrollView :offset="100">
        <template slot-scope="visibility">
          <div class="list-container" key="right">
            <ListItem v-for="(item, index) in lists"
            :visible="visibility[index]"
            :data="item"
            :key="index"
            :type="type"
            />
          </div>
        </template>
      </ScrollView>
    </div>
  </div>
</template>
<script>
import ListItem from './list-item'
import className from 'classnames'
import Left from './left-item.vue'
import { ScrollView } from 'vue-scrollview'
export default {
  props: {
    lists: {
      type: Array,
      default: () => []
    },
    index: Number,
    text: {
      type: Object,
      default: () => {}
    },
    type: { // 0左边 1右边
      type: Number,
      default: 0
    }
  },
  computed: {
    leftClass () {
      return className('cell', {
        'left-cell': this.type === 0,
        'right-cell': this.type === 1
      })
    },
    rightClass () {
      return className('cell', {
        'left-cell': this.type === 1,
        'right-cell': this.type === 0
      })
    }
  },
  components: {
    ListItem,
    ScrollView,
    Left
  }
}
</script>
<style lang="less" scoped>
  .steps-box {
    display: flex;
    min-height: 280px;
    padding-bottom: 40px;
    .cell {
      flex: 1;
    }
    &-tit {
      color: #336fd0;
      font-size: 24px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .list-container {
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    .left-cell {
      order: 1;
    }
    .right-cell {
      order: 2;
    }
  }
</style>
