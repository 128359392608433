<template>
  <div>
    <div class="mainWarpPadd50">
      <div class="title">
        <img
          src="http://www.pekhongyuan.com/pic/hongyuan/images/title-bussiness2.png"
          alt="保税物流"
        />
      </div>
      <div class="instructions">
        <p>
          宏远保税业务始于2007年，经15年保税业务领域经验积累，受到如强生医疗、嘉德、辛克、DHL、康德乐等全球知名企业客户信任。目前，宏远保税业务拥有超50人的专业化服务团队，能为客户提供境外空海运入区/出区、国产品一日游、转口贸易、保税仓储、国际中转、展览展示、电商保税备货、分类监管等多样化服务。
        </p>
      </div>
      <p class="f-title">
        宏远保税业务年操作量连续15年在北京天竺综合保税区名列前茅。
      </p>
    </div>
    <template v-if="clientWidth > 768">
      <div class="bgImgRow">
        <div class="row-main">
          <dl class="flex-btwn row-r">
            <dt>
              <img
                src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness31.jpg"
              />
            </dt>
            <dd>
              <br />
              <p class="f1">宏远保税库</p>
              <p>
                宏远保税仓库位于北京天竺综合保税区，是目前区内最大的单体仓储区域。仓库分为仓库和卸货停车区两个部分，其中仓库总面积约为12000平方米，卸货区及停车场约10000平方米。仓库位于独立区域，便于管理和安全保障。在安保方面，保安7x24小时值守，整体库
                区配备超过80个监控摄像头，对库内、卸货平台、停车场、办公区等区域进行全方位、无死角的安全管理。
              </p>
              <p>
                根据海关的监管要求，库内分保税货物、非保税货物、电商类货物；按区域可分：保税区域、非保区域、先入区后申报区域以及电商区域。同时，根据客户的不同，分长期仓储区和短期仓储区、货架区和大货区，各仓储区即可独立运营，也可统一管理。库内，宏远为客户提供报关、进出库、库内分拣、包装、检验、贴标、上下架等多种服务，并可根据客户需求提供如7x24小时出入库服务等定制化服务。
              </p>
            </dd>
          </dl>
        </div>
      </div>
      <div class="bgImgRow">
        <div class="row-main">
          <dl class="flex-btwn row-l">
            <dd>
              <p>
                库内除普通库位外，宏远保税库拥有4个恒温库，恒温库可以实现库内10-25度恒温，湿度30%-70%可调控，同时库内有全方位监控，保障仓储质量。目前恒温库用于存放艺术品、药品、医疗器械、医疗设备、航材等有恒温要求的货物。
              </p>
              <p>
                库内具有完善的库房管理系统，包括库房配备保税仓储系统、电商备货仓储系统、海关监管平台系统、申报系统等，可以做到即时仓储数量、锁定日期核对仓储数量等数据支持，并能够实现先到先出、指定先出等多种灵活的出入库形式。
              </p>
            </dd>
            <dt>
              <img
                src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness32.jpg"
              />
            </dt>
          </dl>
        </div>
      </div>
    </template>
    <template v-if="clientWidth <= 768">
      <div class="bgImgRow">
        <div class="row-main">
          <dl class="flex-btwn row-r">
            <dd>
              <p class="f1">宏远保税库</p>
              <p>
                宏远保税仓库位于北京天竺综合保税区，是目前区内最大的单体仓储区域。仓库分为仓库和卸货停车区两个部分，其中仓库总面积约为12000平方米，卸货区及停车场约10000平方米。仓库位于独立区域，便于管理和安全保障。在安保方面，保安7x24小时值守，整体库
                区配备超过80个监控摄像头，对库内、卸货平台、停车场、办公区等区域进行全方位、无死角的安全管理。
              </p>
              <p>
                根据海关的监管要求，库内分保税货物、非保税货物、电商类货物；按区域可分：保税区域、非保区域、先入区后申报区域以及电商区域。同时，根据客户的不同，分长期仓储区和短期仓储区、货架区和大货区，各仓储区即可独立运营，也可统一管理。库内，宏远为客户提供报关、进出库、库内分拣、包装、检验、贴标、上下架等多种服务，并可根据客户需求提供如7x24小时出入库服务等定制化服务。
              </p>
            </dd>
          </dl>
          <dt>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness31.jpg"
            />
          </dt>

          <!-- -->
        </div>
      </div>
      <div class="bgImgRow">
        <div class="row-main">
          <dl class="flex-btwn row-l">
            <dd>
              <p>
                库内除普通库位外，宏远保税库拥有4个恒温库，恒温库可以实现库内10-25度恒温，湿度30%-70%可调控，同时库内有全方位监控，保障仓储质量。目前恒温库用于存放艺术品、药品、医疗器械、医疗设备、航材等有恒温要求的货物。
              </p>
              <p>
                库内具有完善的库房管理系统，包括库房配备保税仓储系统、电商备货仓储系统、海关监管平台系统、申报系统等，可以做到即时仓储数量、锁定日期核对仓储数量等数据支持，并能够实现先到先出、指定先出等多种灵活的出入库形式。
              </p>
            </dd>
          </dl>
          <dt>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness32.jpg"
            />
          </dt>
        </div>
      </div>
    </template>

    <div class="mainWarpPadd50">
      <div class="mb-100">
        <p class="li-title">
          优势特色
        </p>
        <ul>
          <li>
            <p class="f1">唯一性：</p>
            <p class="f2">
              北京天竺综合保税区唯一一家可同时提供分送集报、先入区后申报、分类监管、智能卡口的AEO高级认证物流企业。
            </p>
          </li>
          <li>
            <p class="f1">灵活性：</p>
            <p class="f2">
              空海铁运输方式随意组合，零进整出、整进零出、简单加工、自操作、代操作等各种仓储方式随意组合。
            </p>
          </li>
          <li>
            <p class="f1">低成本：</p>
            <p class="f2">
              空海运低于口岸监管区的仓储费用，适合仓储期较长的进口货物。
            </p>
          </li>
          <li>
            <p class="f1">专业性：</p>
            <p class="f2">
              13000平米区内保税仓储面积，唯一一家拥有4个共计1000平米的综合恒温恒湿库。
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      clientWidth: document.documentElement.clientWidth
    }
  }
}
</script>
<style lang="less" scoped>
p,
dl {
  margin-bottom: 0;
}
.title {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}
.instructions {
  font-size: 14px;
  color: #555;
  line-height: 2;
  p {
    margin-bottom: 70px;
  }
  text-align: center;
}
.bgImgRow {
  margin-bottom: 0;
  // height: 650px;
}
.f-title {
  height: 170px;
  margin-bottom: 100px;
  font-size: 16px;
  color: #555;
  text-align: center;
  line-height: 170px;
  background-color: #e7f3ff;
}
dl {
  align-items: center;
  dt {
    width: 37.3%;
  }
  img {
    width: 100%;
  }
  dd {
    width: 62.7%;
    font-size: 14px;
    color: #555;
    line-height: 1.8;
    box-sizing: border-box;
    p + p {
      margin-top: 40px;
    }
    .f1 {
      font-size: 16px;
      color: #333;
    }
  }
}
.row-r dd {
  padding-left: 40px;
}
.row-l dd {
  padding-right: 40px;
}
.li-title {
  padding-top: 90px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #555;
}
li {
  padding: 20px 0 20px 30px;
  font-size: 14px;
  color: #333;
  line-height: 2;
  list-style: none;
  position: relative;
  &:before {
    content: ' ';
    position: absolute;
    left: 10px;
    top: 10px;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    margin-top: 18px;
    background: #d2d2d2;
    border-radius: 50%;
  }
  .f1 {
    margin-bottom: 5px;
    float: left;
  }
  .f2 {
  }
}
@media screen and (max-width: 900px) {
  .mainWarpPadd50 {
    padding: 0 15px;
  }
  .title {
    overflow: hidden;
  }
  .title img {
    width: 100%;
    transform: scale(2.4);
  }
  .bgImgRow .row-main {
    padding: 0 15px;
    display: block;
  }
  .bgImgRow {
    height: auto;
    padding-bottom: 15px;
  }
  .row-r dd {
    padding: 0;
  }
  .row-l dd {
    padding-right: 0;
  }
  dl dd {
    width: auto;
  }
  .row-main img {
    width: 100%;
  }
  li .f1 {
    margin-bottom: 0;
  }
  .f-title {
    display: flex;
    align-items: center;
    line-height: 30px;
    padding: 0 15px;
  }
}
</style>
