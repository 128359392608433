<template>
  <div class="item">
    <div :class="imageClass">
      <div class="hd-title">强大的口岸能力</div>
      <div class="hd-p">强大的口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力口岸能力</div>
    </div>
    <div :class="titleClass">
      <img :src="bg" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: Number,
      default: 1
    },
    bg: {
      type: String,
      default: ''
    }
  },
  computed: {
    imageClass () {
      if (this.type === 1) {
        return ['item-hd', 'right']
      }
      return ['item-hd']
    },
    titleClass () {
      if (this.type === 1) {
        return ['item-image', 'left']
      }
      return ['item-image']
    }
  }
}
</script>
<style lang="less" scoped>
  .item {
    margin: 20px 0;
    display: flex;
    position: relative;
    height: 330px;
    &-hd {
      background: rgba(0, 0,0, 0.4);
      padding: 20px;
      width: 600px;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 40px;
      z-index: 2;
      &.right {
        right: 0;
        left:inherit;
      }
    }
    .hd-title {
      font-size: 20px;
      color: #fff;
      margin-bottom: 10px;
    }
    .hd-p {
      color: #fff;
    }
    &-image {
      position: absolute;
      right: 0;
      width: 900px;
      &.left {
        left: 0;
      }
      img {
        width: 100%;
      }
    }
  }
</style>
