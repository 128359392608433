<template>
  <div :class="className">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    className () {
      return ['box', `${this.type}`]
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  padding-top: 40px;
  margin-bottom: 40px;
}
.style1 {
  min-width: 1200px;
  padding-top: 40px;
  margin-bottom: 40px;
  background: url('http://www.pekhongyuan.com/pic/hongyuan/images/bg2.jpg')
    no-repeat center top;
  background-size: 100% 100%;
}
.img {
  width: 100%;
}

@media screen and(max-width:900px) {
  .style1 {
    min-width: auto;
  }
  .flex-btwn {
    display: block !important;
  }
  .w1200 {
    min-width: auto;
  }
  .part3 .img {
    min-width: auto;
    width: 100%;
  }
}
</style>
