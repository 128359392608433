export const data = [{
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/052209.jpg',
  title: '员工大会',
  detail: '每年举办的员工大会和新年晚会都是日照钢铁年度最重要的回忆，员工大会上向员工传递公司战略 、业务布局与管理思路，并表彰优秀团队。'
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/052210.jpg',
  title: '公司年会',
  detail: '每年举办的员工大会和新年晚会都是日照钢铁年度最重要的回忆，员工大会上向员工传递公司战略 、业务布局与管理思路，并表彰优秀团队。'
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/052211.jpg',
  title: '先进个人',
  detail: '每年举办的员工大会和新年晚会都是日照钢铁年度最重要的回忆，员工大会上向员工传递公司战略 、业务布局与管理思路，并表彰优秀团队。'
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/052212.jpg',
  title: '宏远杯运动会',
  detail: '每年举办的员工大会和新年晚会都是日照钢铁年度最重要的回忆，员工大会上向员工传递公司战略 、业务布局与管理思路，并表彰优秀团队。'
}]
