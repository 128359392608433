<template>
  <div class="main">
    <PartTitle title="核心价值观" :src="tit1" />
    <!-- <div class="w1200 flex-btwn mb40 relative">
      <Item v-for="(item, index) in ITEMS" :key="index" :item="item" />
    </div> -->
    <!-- <PartTitle title="企业愿景" :src="tit2" /> -->
    <!-- <img
      src="http://www.pekhongyuan.com/pic/hongyuan/images/060713.png"
      style="width:100%;margin-bottom: 40px;"
    /> -->
    <Bg src="http://www.lndfwljt.com/image/2021/07/09/052901.png" :height="600">
      <div class="relative">
        <div class="part3-item first">
          <div class="context">
            <div class="f1">企业愿景</div>
            <div class="f2">CORPORATE VISION</div>
            <div class="f3">
              核心价值观:以客户为中心，以奋斗者为本诚信、包容、敬业、担当<br />
            </div>
          </div>
          <div class="part3-item-overlay" />
        </div>
        <div class="part3-item second">
          <div class="context">
            <div class="f1">企业使命</div>
            <div class="f2">CORPORATE MISSION</div>
            <div class="f3">
              赋能单体药店，助力财富增长实现创业者一家药店的梦想
            </div>
          </div>
          <div class="part3-item-overlay" />
        </div>
      </div>
    </Bg>
  </div>
</template>
<script>
import PartTitle from '../../../public/box-title'
// import Item from './item'
import Bg from '../../../public/bg'

import { data as ITEMS } from './data.json'

export default {
  components: {
    // Item,
    PartTitle,
    Bg
  },
  data () {
    return {
      bg: 'http://www.pekhongyuan.com/pic/hongyuan/images/052304.png',
      tit1: 'http://www.pekhongyuan.com/pic/hongyuan/images/052304.png',
      tit2: 'http://www.pekhongyuan.com/pic/hongyuan/images/060710.png',
      ITEMS
    }
  },
  mounted () {
    window.scrollTo(0, 500)
  }
}
</script>
<style lang="less">
.relative {
  height: 600px;
}
.main {
  margin: 0 auto;
  .w800 {
    width: 640px;
    margin: 0 auto;
  }
  .w800 > p,
  .w800 > div {
    color: white !important;
  }
  .mb40 {
    margin-bottom: 80px;
  }
  .part {
    margin-bottom: 120px;
    width: 1919px;
    height: 545px;
    background: url("http://www.pekhongyuan.com/pic/hongyuan/images/060713.png")
      no-repeat;
    background-size: 100% 100%;
    text-align: center;
  }
}
.part3-item {
  width: 50%;
  height: 300px;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  cursor: pointer;
  &.first {
    right: 0;
    top: 0;
    .context {
      padding: 60px;
      padding-left: 40px;
    }
  }
  &.second {
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    .context {
      padding: 70px;
      padding-left: 40px;
      min-width: 600px;
      max-width: 760px;
      width: 100%;
    }
  }

  .context {
    color: rgb(11, 69, 149);
    position: relative;
    z-index: 100;
    .f1 {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .f2 {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .f3 {
      font-size: 16px;
    }
  }
  &-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    background: #529fcc;
    z-index: 1;
    transition: 300ms ease-in-out;
  }
  &:hover {
    .part3-item-overlay {
      height: 300px;
    }
    .context {
      color: #fff;
    }
  }
}
@media screen and (max-width: 900px) {
  .flex-btwn {
    display: block;
  }
}
@media screen and(max-width:900px) {
  .title {
    overflow: hidden;
  }
  .title img {
    width: 100%;
    transform: scale(2.4);
  }
  .title .img9 {
    width: 100%;
    transform: scale(2.4);
  }
  .left img {
    width: 100%;
    margin: 0 auto;
    // display: block;
  }
  .detail {
    display: block;
    width: 95%;
    margin: 0 auto;
  }
  .detail .sub-com {
    width: 100%;
  }
  .icon-item .image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .part3-item .context {
    .f1 {
      font-size: 18px;
    }
    .f2 {
      font-size: 14px;
    }
    .f3 {
      font-size: 12px;
      // display: none;
    }
  }
  .part3-item.second .context {
    min-width: auto;
  }
  .part4 .container {
    display: block;
    .item {
      width: 80%;
      margin: 0 auto 15px;
    }
  }
  .relative {
    // height: 300px;
  }
}
</style>
