<template>
  <div>
    <MainHeader />
    <div class="search-banner"></div>
    <!-- 中间搜索区域 -->
    <div class="search-tops">
      <div class="search-box">
        <h2>搜索结果</h2>
        <div class="search-box-input">
          <a-input-search
            placeholder="搜索关键字"
            enter-button
            @search="onSearch"
            v-model="keyword"
          />
        </div>
      </div>
      <div class="search-container">
        <div class="search-container-title">
          <span
            >共<span class="red">{{ totalSize }}</span
            >条记录</span
          >
        </div>
        <div class="search-container-list">
          <Item v-for="(item, index) in list" :key="index" :item="item" />
        </div>
      </div>
      <div class="search-page">
        <a-pagination
          v-model="page.current"
          :total="page.total"
          :pageSize="pageSize"
          show-less-items
          @change="onChange"
        />
      </div>
    </div>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Item from './item.vue'
import { search } from '@/api/home'

export default {
  components: {
    MainFooter,
    MainHeader,
    Item
  },
  data () {
    return {
      list: [],
      pageNum: 1,
      pageSize: 7,
      keyword: '',
      page: {
        total: '',
        current: ''
      },
      totalSize: ''
    }
  },
  mounted: function () {
    this.keyword = this.$route.params.find
    if (this.keyword) {
      this.onSearch()
    }
  },
  methods: {
    async onSearch () {
      const searchData = await search({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        info: this.keyword
      })
      if (searchData.code === 200) {
        console.log(searchData.data)
        this.list = searchData.data.content
        this.page.total = searchData.data.totalSize
        this.page.current = searchData.data.pageNum
        this.totalSize = searchData.data.totalSize
      }
    },
    onChange (pageNumber) {
      this.pageNum = pageNumber
      this.onSearch()
    }
  }
}
</script>
<style lang="less" scoped>
.search-banner {
  height: 450px;
  background-image: url('../../assets/s.jpg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 450px;
}

.search-tops {
  width: 1200px;
  margin: 0 auto;
}

.search-box {
  h2 {
    text-align: center;
    color: #336fd0;
    padding: 20px 0;
    padding-top: 80px;
  }

  .search-box-input {
    width: 600px;
    margin: 0 auto;
  }
}

.search-container {
  padding-top: 80px;
  padding-bottom: 40px;

  &-title {
    font-size: 18px;
    border-bottom: 1px solid #eee;
    padding: 20px 0;
  }
}

.search-page {
  text-align: right;
  padding-bottom: 40px;
}

.red {
  color: red;
}
@media screen and(max-width:900px) {
  .search-banner {
    height: 250px;
    background-image: url('../../assets/s.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
  }
  .search-tops {
    width: auto;
    margin: 0 auto;
  }

  .search-box {
    .search-box-input {
      width: 90%;
      margin: 0 auto;
    }
  }
  .search-container {
    width: 90%;
    margin: 0 auto;
  }
}
</style>
