<template>
  <div class="header">
    <div class="header-panel">
      <div class="logo">
        <a href="#/" @click="toggle"><img alt="宏远控股" src="../../../assets/logo.png"/></a>
      </div>
      <div class="navAll">
        <Menus />
      </div>
    </div>
  </div>
</template>
<script>
import Menus from './menu'

export default {
  name: 'MainHeader',
  components: {
    Menus
  },
  methods: {
    toggle () {
      this.$router.push({
        name: 'main'
      })
    }
  }
}
</script>
<style lang="less" scoped>
// 加一个端点
@media screen and (max-width: 1199px) {
  .header {
    width: 1200px;
  }
}

.header {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1000;
  overflow: hidden;
  // min-width: 1200px;
}
.header-panel {
  max-width: 1520px;
  min-width: 1200px;
  margin: auto;
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.header .logo {
  width: 120px;
  height: 26px;
  float: left;
  img {
    width: 120px;
  }
}
@media screen and (max-width: 900px) {
  .logo {
    display: none;
  }
}
</style>
