var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('MainHeader'),_c('Banner',{attrs:{"bgImg":_vm.bgImg,"TopTitle":_vm.TopTitle,"secondTitle":_vm.secondTitle}},[_c('div',{staticClass:"banner-item w1200 align-center"})]),_c('PartTitle',{attrs:{"src":_vm.tit1}}),_vm._m(0),(_vm.clientWidth <= 900)?_c('div',{staticClass:"part2 w1200 flex-btwn"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]):_vm._e(),(_vm.clientWidth > 900)?_c('div',{staticClass:"part2 w1200 flex-btwn"},[_c('div',{staticClass:"item",on:{"mouseenter":_vm.enter,"mouseleave":_vm.leave}},[_vm._m(6),_vm._m(7)]),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)]):_vm._e(),(_vm.clientWidth <= 900)?_c('div',{staticClass:"bgImgRow"},[_c('div',{staticClass:"partner"},[(_vm.slides.length > 0)?_c('carousel-3d',{attrs:{"display":3,"border":0,"width":"609","height":"432","autoplay":""}},_vm._l((_vm.slides),function(item,index){return _c('slide',{key:index,attrs:{"index":index}},[_c('img',{attrs:{"src":item.imageUrl}})])}),1):_vm._e()],1)]):_vm._e(),(_vm.clientWidth > 900)?_c('div',{staticClass:"part3 w1200"},[_c('div',{staticClass:"bd"},[(this.slides.length > 0)?_c('carousel-3d',{attrs:{"display":3,"height":352,"width":500,"border":0,"controlsVisible":"","autoplay":""}},_vm._l((_vm.slides),function(item,index){return _c('slide',{key:index,attrs:{"index":index}},[_c('img',{attrs:{"src":item.imageUrl,"alt":""}})])}),1):_vm._e()],1)]):_vm._e(),_c('MainFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1200 part1"},[_c('div',{staticClass:"row"},[_vm._v(" 依托临空经济，宏远于2001年开始进军地产行业，先后开发了宏远·天竺商务园、五矿财富中心、宏远·航城广场、宏远·临空总部港、空港宏远国际货运分拨中心等地标性项目，涵盖5A级写字楼、公寓、商业综合体等多种业态，累计开发面积近百万平方米，取得国家房地产开发企业四级资质。 ")]),_c('div',{staticClass:"row"},[_vm._v(" 此外，宏远集团还搭建了一只训练有素的物业管理服务团队，以标准化、规范化、制度化服务获得物业管理企业二级资质，管理办公、仓储、商业、酒店等多种业态，合同面积近70万平方米。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"big"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061450.jpg"}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"l"},[_vm._v("宏远·天竺商务园")])]),_c('div',{staticClass:"r"},[_vm._v(" 宏远·天竺商务园一期仓储项目最早于2003年2月建成，经七期工程扩建，最后一期项目于2019年6月竣工。项目位于北京市顺义区临空经济核心区，建筑面积约8.1万平方米，是涵盖写字楼、酒店、仓储库房、餐饮、商铺等功能的多功能、综合性商业建筑群。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"big"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061451.jpg"}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"l"},[_vm._v("五矿财富中心")])]),_c('div',{staticClass:"r"},[_vm._v(" 五矿财富中心于2013年5月接收运营，位于北京市丰台区，项目毗邻北京地铁14号线，交通便利。项目整体建筑面积约0.6万平方米，现建有三层商业办公楼两栋，并涵盖银行、餐饮等多种商业业态。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"big"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061452.jpg"}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"l"},[_vm._v("宏远·航程广场")])]),_c('div',{staticClass:"r"},[_vm._v(" 宏远·航城广场于2014年1月竣工，位于北京市顺义区临空经济核心区，建筑面积约17.3万平方米，是具备多种功能、一站式规划、融合可持续发展理念的空港都市综合体。项目融合5A写字楼、商业、酒店、SOHO办公，等多种城市业态，将商务，商业，住宿等多功能城市空间通过合理的交通连接组合，相互依存、协同促进，形成了多元化的综合空间。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"big"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061453.jpg"}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"l"},[_vm._v("宏远·临空总部港")])]),_c('div',{staticClass:"r"},[_vm._v(" 宏远•临空总部港于2016年9月竣工，位于北京顺义区临空经济核心区。宏远•临空总部港以24栋花园式独栋建筑组成，涵盖写字楼、商办公寓、酒店及配套商业等。项目总用地面积2.1万平方米、总建筑面积9.1万平方米，其中单栋建筑面积800至3100平米，充分满足规模企业的办公服务需求。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"big"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061454.jpg"}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"l"},[_vm._v("空港宏远"),_c('br'),_vm._v("国际货运分拨中心")])]),_c('div',{staticClass:"r"},[_vm._v(" 空港宏远国际货运分拨中心2021年落成，位于北京天竺综合保税区内，是宏远为2022年北京冬奥会专项打造的重要非竞赛场馆，是家具白电、技术设备、体育器材等办赛物资的集中存储场所。项目采用国际先进的仓储设计理念，总建筑面积达8.1万平方米。项目毗邻机场高速，享有更快捷的通关模式、更便利的交通出行和更低廉的仓储成本，可最大程度保障物资调配的便利性和时效性。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"small"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061445.jpg"}}),_c('span',{staticClass:"text"},[_vm._v("宏远·天竺商务园")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"big"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061450.jpg"}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"l"},[_vm._v("宏远·天竺商务园")]),_c('div',{staticClass:"r"},[_vm._v(" 宏远·天竺商务园一期仓储项目最早于2003年2月建成，经七期工程扩建，最后一期项目于2019年6月竣工。项目位于北京市顺义区临空经济核心区，建筑面积约8.1万平方米，是涵盖写字楼、酒店、仓储库房、餐饮、商铺等功能的多功能、综合性商业建筑群。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"small"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061446.jpg"}}),_c('span',{staticClass:"text"},[_vm._v("五矿财富中心")])]),_c('div',{staticClass:"big"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061451.jpg"}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"l"},[_vm._v("五矿财富中心")]),_c('div',{staticClass:"r"},[_vm._v(" 五矿财富中心于2013年5月接收运营，位于北京市丰台区，项目毗邻北京地铁14号线，交通便利。项目整体建筑面积约0.6万平方米，现建有三层商业办公楼两栋，并涵盖银行、餐饮等多种商业业态。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"small"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061447.jpg"}}),_c('span',{staticClass:"text"},[_vm._v("宏远·航程广场")])]),_c('div',{staticClass:"big"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061452.jpg"}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"l"},[_vm._v("宏远·航程广场")]),_c('div',{staticClass:"r"},[_vm._v(" 宏远·航城广场于2014年1月竣工，位于北京市顺义区临空经济核心区，建筑面积约17.3万平方米，是具备多种功能、一站式规划、融合可持续发展理念的空港都市综合体。项目融合5A写字楼、商业、酒店、SOHO办公，等多种城市业态，将商务，商业，住宿等多功能城市空间通过合理的交通连接组合，相互依存、协同促进，形成了多元化的综合空间。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"small"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061448.jpg"}}),_c('span',{staticClass:"text"},[_vm._v("宏远·临空总部港")])]),_c('div',{staticClass:"big"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061453.jpg"}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"l"},[_vm._v("宏远·临空总部港")]),_c('div',{staticClass:"r"},[_vm._v(" 宏远•临空总部港于2016年9月竣工，位于北京顺义区临空经济核心区。宏远•临空总部港以24栋花园式独栋建筑组成，涵盖写字楼、商办公寓、酒店及配套商业等。项目总用地面积2.1万平方米、总建筑面积9.1万平方米，其中单栋建筑面积800至3100平米，充分满足规模企业的办公服务需求。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"small"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061449.jpg"}}),_c('span',{staticClass:"text"},[_vm._v("空港宏远"),_c('br'),_vm._v("国际货运分拨中心")])]),_c('div',{staticClass:"big"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/061454.jpg"}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"l"},[_vm._v("空港宏远"),_c('br'),_vm._v("国际货运分拨中心")]),_c('div',{staticClass:"r"},[_vm._v(" 空港宏远国际货运分拨中心2021年落成，位于北京天竺综合保税区内，是宏远为2022年北京冬奥会专项打造的重要非竞赛场馆，是家具白电、技术设备、体育器材等办赛物资的集中存储场所。项目采用国际先进的仓储设计理念，总建筑面积达8.1万平方米。项目毗邻机场高速，享有更快捷的通关模式、更便利的交通出行和更低廉的仓储成本，可最大程度保障物资调配的便利性和时效性。 ")])])])])
}]

export { render, staticRenderFns }