<template>
  <div>
    <div class="contact-side none">
      <p class="tit">分享</p>
      <div class="flex-btwn">
        <a :href="weiboUrl" target="_blank"
          ><img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/io-wb.png"
            alt="微博"
        /></a>
        <a-popover>
          <a href="">
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/io-wechat.png"
              alt="微信"
            />
          </a>
          <template slot="content">
            <VueQr :text="newUrl" />
          </template>
        </a-popover>
        <a :href="qZoneUrl" target="_blank"
          ><img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/io-qq.png"
            alt="QQ"
        /></a>
      </div>
    </div>
    <div class="contact-side">
      <p class="tit">推荐阅读</p>
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <a target="_blank" @click="goNew(item)"
            ><p class="txt">{{ item.title }}</p></a
          >
          <p class="date">{{ item.createTime }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import VueQr from 'vue-qr'
import { recommended } from '@/api/news'
import { shareWeibo, shareQzone } from './utils'

export default {
  props: {
    article: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      list: []
    }
  },
  async mounted () {
    const data = await recommended()
    if (data.code === 200) {
      this.list = data.data.records
    }
  },
  computed: {
    newUrl () {
      // 等网站上线后设置为线上的url
      return location.href
    },
    weiboUrl () {
      return shareWeibo({
        ...this.article,
        link: this.newUrl
      })
    },
    qZoneUrl () {
      return shareQzone({
        ...this.article,
        link: this.newUrl
      })
    }
  },
  methods: {
    goNew (item) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          type: item.type,
          pageId: item.id
        }
      })
    }
  },
  components: {
    VueQr
  }
}
</script>
<style lang="less" scoped>
.contact-side {
  width: 400px;
  margin-bottom: 20px;
  padding: 5px 25px 25px;
  background-color: #f4f6fa;
  .tit {
    margin-bottom: 20px;
    font-size: 32px;
    color: #333;
    font-weight: bold;
    line-height: 3;
    border-bottom: 1px solid #6cbbe9;
  }
  li {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    .date {
      margin-top: 10px;
      font-size: 14px;
      color: #555;
    }
  }
  li + li {
    border-top: 1px solid #ddd;
  }
}
@media screen and(max-width:900px) {
  .none {
    display: none;
  }
  .contact-side {
    width: 100%;
    .tit {
      font-size: 14px;
    }
    .flex-btwn {
      img {
        width: 50px;
      }
    }
  }
}
</style>
