<template>
  <div class="j-container">
    <MainHeader />
    <Banner :bgImg="bgImg">
      <div class="banner-item w1200 align-center">
        <img src="http://www.pekhongyuan.com/pic/hongyuan/images/052201.png" />
      </div>
    </Banner>
    <!-- 加入我们 -->
    <div class="title">
      <img src="http://www.pekhongyuan.com/pic/hongyuan/images/052205.png" />
    </div>
    <!-- 在宏远工作 -->
    <Box type="style1">
      <div class="part w1200">
        <div class="tit">
          在宏远工作
        </div>
        <div class="sub-tit">
          你将获得
        </div>
        <div class="bd">
          <Carousel />
        </div>
      </div>
    </Box>
    <!-- 工作环境 -->
    <Box>
      <div class="part w1200">
        <div class="tit">工作环境</div>
        <div class="sub-tit">宽敝舒适的工作环境，惬意享受员工生活</div>
        <div class="bd flex-btwn">
          <EnvItem v-for="(item, index) in  envData" :key="index" :env="item" />
        </div>
      </div>
    </Box>
    <!-- 员工活动 -->
    <Box class="bggery">
      <div class="part w1200">
        <div class="tit">员工活动</div>
        <div class="sub-tit">以丰富多彩的活动，深入人心的文化品牌，将文化的信念不断传承。</div>
        <div class="bd flex-btwn flex-wrap">
          <ActItem v-for="(item, index) in  actData" :key="index" :act="item" />
        </div>
      </div>
    </Box>
    <!-- 我要应聘 -->
    <!-- <a class="join-btn">我要应聘 ></a> -->
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import Box from '../public/box'
import Carousel from './carousel'
import EnvItem from './env-item'
import ActItem from './act-item'

import { data as actData } from './act.json'
export default {
  components: {
    MainFooter,
    MainHeader,
    Banner,
    Box,
    Carousel,
    EnvItem,
    ActItem
  },
  data () {
    return {
      bgImg: 'http://www.pekhongyuan.com/pic/hongyuan/images/052202.jpg',
      actData,
      envData: [{
        src: 'http://www.pekhongyuan.com/pic/hongyuan/images/052207.jpg',
        title: '办公环境',
        detail: '宽敝舒适的办公环境'
      }, {
        src: 'http://www.pekhongyuan.com/pic/hongyuan/images/052208.jpg',
        title: '多元文化',
        detail: '青少年教育活动以及发布的活动报告'
      }]
    }
  }
}
</script>
<style lang="less" scoped>
  .banner-item {
    margin: 0 auto;
  }
  .j-container {
    .title {
      text-align: center;
      margin: 30px 0;
    }
  }
  .part {
    .tit {
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      color: #000;
      margin-bottom: 10px;
    }
    .sub-tit {
      font-size: 16px;
      text-align: center;
      margin-bottom: 40px;
    }
  }
  .bggery {
    background: rgba(243, 247, 250);
  }
  .join-btn {
    display: block;
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 40px;
    background: #336fd0;
    padding: 16px;
    font-size: 24px;
    text-align: center;
    color: #fff;
  }
</style>
