export const data = [{
  value: '0',
  name: '物流服务'
}, {
  value: '1',
  name: '口岸物流'
}, {
  value: '2',
  name: '保税物流'
}, {
  value: '3',
  name: '跨境电商物流'
}, {
  value: '4',
  name: '卡车航班'
}, {
  value: '5',
  name: '航空货运'
}, {
  value: '6',
  name: '海外仓'
}]
