<template>
  <div>
    <MainHeader/>
    <MainBanner v-if="bannerSrc" :src='bannerSrc' :TopTitle="TopTitle" :secondTitle="secondTitle" :thirdTitle="thirdTitle"/>
    <Main :page="current"/>
    <MainFooter/>
  </div>
</template>
<script>
import MainHeader from '../public/header'
import MainBanner from '../public/simpleBanner'
import MainFooter from '../public/footer'
import Main from './components/main'
import { data as menus } from './menus.json'
import { getBannerById } from '@/api/system'
// import bannerImg0 from 'http://www.pekhongyuan.com/pic/hongyuan/images/banner-bussiness.jpg'
// import bannerImg1 from 'http://www.pekhongyuan.com/pic/hongyuan/images/banner-bussiness11.jpg'
// import bannerImg2 from 'http://www.pekhongyuan.com/pic/hongyuan/images/banner-bussiness12.jpg'
// import bannerImg3 from 'http://www.pekhongyuan.com/pic/hongyuan/images/banner-bussiness13.jpg'
// import bannerImg4 from 'http://www.pekhongyuan.com/pic/hongyuan/images/banner-bussiness14.jpg'
// import bannerImg5 from 'http://www.pekhongyuan.com/pic/hongyuan/images/banner-bussiness15.jpg'
// import bannerImg6 from 'http://www.pekhongyuan.com/pic/hongyuan/images/banner-bussiness16.jpg'

export default {
  components: {
    MainHeader,
    MainBanner,
    MainFooter,
    Main
  },
  data () {
    return {
      bannerSrc: '',
      menus, // 菜单json
      current: '0',
      TopTitle: '',
      secondTitle: '',
      thirdTitle: '',
      bannerId: ''

    }
  },
  created () {
    this.current = this.$route.params.type
    if (this.current === '0') {
      this.TopTitle = '物流服务'
      this.secondTitle = 'LOGISTICS SERVICES'
      this.bannerId = 3
    }
    if (this.current === '1') {
      this.TopTitle = '口岸物流'
      this.secondTitle = 'PORT OPERATION'
      this.bannerId = 4
    }
    if (this.current === '2') {
      this.TopTitle = '保税物流'
      this.secondTitle = 'BONDED LOGISTICS'
      this.bannerId = 5
    }
    if (this.current === '3') {
      this.TopTitle = '跨境电商物流'
      this.secondTitle = 'CROSS BORDER E-COMMERCE'
      this.bannerId = 6
    }
    if (this.current === '4') {
      this.TopTitle = '卡车航班'
      this.secondTitle = 'TRUCK SHUTTLING SERVICE'
      this.bannerId = 7
    }
    if (this.current === '5') {
      this.TopTitle = '航空货运'
      this.secondTitle = 'AIRFREIGHT'
      this.bannerId = 8
    }
    if (this.current === '6') {
      this.TopTitle = '海外仓 前置仓'
      this.secondTitle = 'OVERSEAS WAREHOUSE'
      this.thirdTitle = 'PREPOSITION WAREHOUSE'
      this.bannerId = 9
    }
  },
  watch: {
    '$route.params': {
      handler (params) {
        if (params.type) {
          this.current = params.type
        } else {
          this.current = '0'
        }
      },
      deep: true
    },
    bannerId: 'getBanner'
  },
  methods: {
    setArticle (v) {
      this.current = v
    },
    async getBanner () {
      const data = await getBannerById({ bannerId: this.bannerId })
      if (data.code === 200) {
        this.bannerSrc = data.data.imageUrl
      }
    }
  }
}
</script>
