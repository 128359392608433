export const data = [{
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/052301.png',
  title: '北京2022年冬奥会祝物流中心建设进入全面收尾阶段',
  time: '2021-05-13 22:24',
  detail: '空港宏远作为北京2022年冬奥会和残奥会官方仓储设施独家供应商，自2019年12月开始建设主物流中只能，项目整体历时400余天，目前，祝物流中心库房的绝大部分建设项目已经完成，平台主题结构全部建设完毕，盘道和室外管网的工作也即将结束，整体工程已进入全面收尾工作。',
  href: ''
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/052301.png',
  title: '北京2022年冬奥会祝物流中心建设进入全面收尾阶段',
  time: '2021-05-13 22:24',
  detail: '空港宏远作为北京2022年冬奥会和残奥会官方仓储设施独家供应商，自2019年12月开始建设主物流中只能，项目整体历时400余天，目前，祝物流中心库房的绝大部分建设项目已经完成，平台主题结构全部建设完毕，盘道和室外管网的工作也即将结束，整体工程已进入全面收尾工作。',
  href: ''
}]
