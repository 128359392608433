<template>
  <div class="env-item">
    <div class="env-item-img">
      <img :src="env.src" />
    </div>
    <div class="env-item-tit">
      <h4 class="tit">{{ env.title }}</h4>
      <span class="detail">{{ env.detail }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    env: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style lang="less" scoped>
  .env-item {
    flex: 1;
    margin: 0 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    overflow: hidden;
    &-img {
      img {
        width: 100%;
      }
    }
    &-tit {
      padding: 10px 16px;
      .tit {
        font-size: 16px;
        color: #000;
        font-weight: bold;
      }
      .detail {
        font-size: 14px;
        color: #000;
      }
    }
  }
</style>
