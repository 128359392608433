<template>
  <div class="containers">
    <MainHeader />
    <Banner :bgImg="bgImg" :TopTitle="TopTitle" :secondTitle="secondTitle">
    </Banner>
    <!-- 加入我们 -->
    <Box>
      <div class="part flex-btwn">
        <div class="part-left">
          <h2>{{ info.name }}</h2>
          <div class="line">
            公司地址：{{ info.address }}<br />
            联系电话：{{ info.phone }}<br />
            邮政编码：{{ info.postcode }}<br />
            官方网址：{{ info.url }}<br />
            品牌合作邮箱：{{ info.email }}<br />
          </div>
        </div>
        <div class="part-right">
          <iframe
            width="600"
            height="300"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            class="if"
            src="https://lbs.amap.com/tools/showmap/?1_500_400_116.614455_40.120321&=%E4%BA%94%E6%B4%B2%E9%80%9A%E5%A4%A7%E8%8D%AF%E6%88%BF&=%E8%88%AA%E5%A4%A9%E8%B7%AF&=&=400-1004-789&=%E4%BA%94%E6%B4%B2%E9%80%9A%E5%A4%A7%E8%8D%AF%E6%88%BF"
          ></iframe>
        </div>
      </div>
    </Box>
    <!-- 第二部门 -->
    <!-- <div class="part2">
      <img src="http://www.pekhongyuan.com/pic/hongyuan/images/061426.jpg" />
    </div> -->
    <!-- 第三部分 -->
    <!-- <div class="part3 w1200">
      <div class="item">
        <h2>上海</h2>
        <p>上海市浦东新区紫明路151号普洛斯祝桥物流园B1号库L2-2号单元仓库</p>
      </div>
      <div class="item">
        <h2>南昌</h2>
        <p>江西省南昌市新建区昌北国际机场国际货运营业厅贴建楼二楼205室206室</p>
      </div>
      <div class="item">
        <h2>石家庄</h2>
        <p>河北省石家庄市正定县新城铺镇机场信息办公楼433室</p>
      </div>
      <div class="item">
        <h2>厦门</h2>
        <p>
          福建省厦门市湖里区翔云一路43号元翔空运货站有限公司大楼厦门空港货运联检报关中心3楼330
        </p>
      </div>
      <div class="item">
        <h2>深圳</h2>
        <p>广东省深圳市龙华区观澜街道环观南路18号铭可达物流园7号库</p>
      </div>
      <div class="item">
        <h2>比利时·布鲁塞尔</h2>
        <p>
          Brucargo, Machelen, Halle Vilvoorde, Flemish Brabant, 1931, Belgium
        </p>
      </div>
      <div class="item">
        <h2>日本·东京</h2>
        <p>東京都大田区羽田旭町10-11 MFIP羽田3F</p>
      </div>
      <div class="item">
        <h2>美国·加利福尼亚</h2>
        <p>2130 E Del Amo Blvd, Compton, CA, USA</p>
      </div>
    </div> -->
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import Box from '../public/box'
import { getFoot, getBannerById } from '@/api/system'

export default {
  components: {
    MainFooter,
    MainHeader,
    Banner,
    Box
  },
  data () {
    return {
      bgImg: 'http://www.lndfwljt.com/image/2021/07/09/061424.jpg',
      TopTitle: '联系我们',
      secondTitle: 'CONTACT US',
      info: {}
    }
  },
  created () {
    this.getInfo()
    this.getBanner()
  },
  methods: {
    async getInfo () {
      const data = await getFoot()
      if (data.code === 200) {
        this.info = data.data
      }
    },
    async getBanner () {
      const data = await getBannerById({ bannerId: 2 })
      if (data.code === 200) {
        this.bgImg = data.data.imageUrl
      }
    }
  }
}
</script>
<style lang="less" scoped>
.banner-item {
  margin: 0 auto;
  padding-top: 260px;
  color: #fff;

  .tit {
    font-size: 72px;
    font-weight: bold;
    margin-bottom: 20px;
    letter-spacing: 10px;
  }

  .subtit {
    font-size: 36px;
  }
}

.container {
  .title {
    text-align: center;
    margin: 30px 0;
  }
}

.part {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 60px;

  &-left {
    color: #000;

    h2 {
      font-weight: bold;
    }

    .line {
      line-height: 3;
    }
  }
}

.part2 {
  img {
    width: 100%;
  }

  margin-bottom: 40px;
}

.part3 {
  margin-bottom: 120px;

  .item {
    margin-bottom: 40px;

    h2 {
      text-align: center;
      font-size: 18px;
    }

    p {
      text-align: center;
    }
  }
}
@media screen and(max-width:900px) {
  .part {
    width: 90%;
    margin: 0 auto;
  }
  .if {
    width: 100%;
  }
  .containers {
  }
}
</style>
