<template>
  <div>
    <div class="mainWarpPadd50">
      <div class="title">
        <img
          src="http://www.pekhongyuan.com/pic/hongyuan/images/title-bussiness6.png"
          alt="海外仓"
        />
      </div>
      <div class="instructions mb-100">
        <p>
          为逐步实现跨境贸易综合服务商的企业愿景，宏远控股集团在中国、日本、欧洲和美国等地布局了一系列的前置仓和海外仓，从而不断完善全链路物流服务的规划。
        </p>
      </div>
    </div>
    <div class="bgImgRow">
      <div class="row-main">
        <dl class="flex-btwn row-r">
          <dt>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness51.jpg"
            />
          </dt>
          <dd>
            <br />
            <p class="f1">
              日本东京羽田仓
            </p>
            <p>
              宏远日本东京羽田仓于2019年正式投入使用，是宏远用“智慧物流”理念打造的跨境电商贸易领域新标杆。仓库距离羽田机场仅800米，距离东京港及横滨港均在5公里范围内，可执行一、二级库操作以及跨境电商直邮服务，有效满足主流电商平台的跨境贸易需求。同时，仓内配套AGV智能货架搬运机器人和自动流水线，大幅节约人工成本，减少人员接触，提升货物运转效率。
            </p>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness51.png"
            />
          </dd>
        </dl>
      </div>
    </div>
    <div class="bgImgRow">
      <div class="row-main">
        <dl class="flex-btwn row-l">
          <dd>
            <p class="f1">
              深圳前置仓
            </p>
            <p>
              宏远深圳前置仓于2021年4月25日开仓运营。深圳仓位于深圳市龙华区观澜街道环观南路，使用面积约10000平方米。
            </p>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness52.png"
            />
          </dd>
          <dt>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness52.jpg"
            />
          </dt>
        </dl>
      </div>
    </div>
    <div class="bgImgRow">
      <div class="row-main">
        <dl class="flex-btwn row-r">
          <dt>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness53.jpg"
            />
          </dt>
          <dd>
            <br />
            <p class="f1">
              上海前置仓
            </p>
            <p>
              宏远上海前置仓于2021年4月25日开仓运营。上海前置仓位于上海浦东新区紫明路151号普洛斯祝桥物流园内，使用面积约4000平方米。
            </p>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness53.png"
            />
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
p,
dl {
  margin-bottom: 0;
}
.title {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}
.instructions {
  font-size: 14px;
  color: #555;
  line-height: 2;
  text-align: center;
  p {
    margin-bottom: 70px;
  }
}
dl {
  align-items: center;
  dt {
    width: 42%;
  }
  img {
    width: 100%;
  }
  dd {
    width: 62.7%;
    font-size: 14px;
    color: #555;
    line-height: 1.8;
    box-sizing: border-box;
    p {
      padding-bottom: 20px;
    }
    .f1 {
      font-size: 16px;
      color: #333;
    }
    img {
      width: 90%;
    }
  }
}
.row-r dd {
  padding-left: 20px;
}
.row-l dd {
  padding-right: 20px;
}
.li-title {
  padding-top: 90px;
  margin-bottom: 30px;
  font-size: 30px;
  color: #555;
}
@media screen and (max-width: 900px) {
  .mainWarpPadd50 {
    padding: 0 15px;
  }
  .title {
    overflow: hidden;
  }
  .title img {
    width: 100%;
    transform: scale(2.4);
  }
  .bgImgRow .row-main {
    padding: 0 15px;
    display: block;
  }
  .bgImgRow {
    height: auto;
    padding-bottom: 15px;
  }
  .row-r dd {
    padding: 0;
  }
  dl dd {
    width: auto;
  }
  .row-main img {
    width: 100%;
  }
  li .f1 {
    margin-bottom: 0;
  }
  .f-title {
    display: flex;
    align-items: center;
    line-height: 100%;
  }
  .flex-btwn {
    display: block;
    padding: 15px 0;
  }
  dl dt {
    width: auto;
  }
}
</style>
