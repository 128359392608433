<template>
  <div>
    <!-- <PartTitle title="宏远党建工作" :src="titleImg"/> -->
    <!-- 第一部分 -->
    <!-- <div class="w1200 background-red">
      <div class="border-red">
        <div class="p">2001年，北京宏远物流有限责任公司成立桉楹联合党支部。2018年1月，原桉楹联合党支部重新组建为宏远集团党支部、空港宏远党支部和天保宏远党支部。2019年5月，为了进一步加强党的领导和基层组织建设，根据《中国共产党程》、《中国共产党党和国家机关基层组织工作条例》及集团公司开展党建工作需要，向上级党委天竺综保区机关党委申请成立中共宏远控股集团（总）支部委员会，简称宏远控股集团党总支，下设4个基层党支部，隶属于天竺综保区非公党委。</div><div class="p">当前，党建工作已经融入到公司的各类经营中，发挥党组织的政治优势，转变观念，统一思想，为全面完成各项工作指标奠定了思想基础和强有力的政治保障。在党和国家的指导引领下，不断提升服务质量，打造超一流跨境贸易服务品牌，不断践行“以客户为中心、以奋斗者为本、拥抱变化、协同担当”的企业核心价值观。</div>
        <div class="p-title">
          宏远控股集团党总支党建工作纪实
        </div>
        <div class="flex-btwn flex-wrap part-bd">
          <GridItem v-for="(item, index) in dataList" :key="index" :item="item" />
        </div>
        <div class="load-more" v-show="more">
          <div class="icon" @click="getData"></div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
// import { Carousel, Item } from '../../../public/carousel'
// import PartTitle from '../../../public/box-title'
// import GridItem from './item.vue'
import { getPartyBuildingInfo } from '@/api/about'
import { data as ITEMS } from './slide.json'
import { data as GRIDITEMS, others } from './grid.json'

export default {
  props: ['detail'],
  // components: { PartTitle, GridItem },
  data () {
    return {
      ITEMS,
      GRIDITEMS,
      titleImg: 'http://www.pekhongyuan.com/pic/hongyuan/images/061411.png',
      dataList: [],
      pageIndex: 0,
      more: true,
      others: [...others]
    }
  },
  methods: {
    // handleNext () {
    //   const items = this.others.splice(0, 3)
    //   if (items.length > 0) {
    //     this.GRIDITEMS.splice(this.GRIDITEMS.length, 0, ...items)
    //   }
    // },
    async getData (page) {
      this.pageIndex += 1
      const moreList = await getPartyBuildingInfo({ pageNum: this.pageIndex, pageSize: 3 })
      if (moreList.data.records != null && moreList.code === 200) {
        this.dataList = this.dataList.concat(moreList.data.records)
        console.log(this.dataList)
        if (this.pageIndex >= moreList.data.pages) {
          this.more = false
        }
      }
    }
  },
  mounted () {
    window.scrollTo(0, 500)
    this.getData()
  }
}
</script>
<style lang='less' scoped>
  .background-red {
    border-color: #9b1f25;
    border-width: 6px;
    border-style: solid;
    border-bottom: none;
    padding: 8px;
    padding-bottom: 0;
    background-color: #f2e6e6;
    background-image: url('http://www.pekhongyuan.com/pic/hongyuan/images/061413.png');
    background-repeat: no-repeat;
    background-size: 263px 227px;
    background-position: center 30px;
  }
  .border-red {
    border-color: #9b1f25;
    border-width: 4px;
    border-style: solid;
    border-bottom: none;
    padding: 8px;
    padding-top: 30px;
    .p {
      color: #9b1f25;
      line-height: 2.5;
      text-indent: 2em;
      padding: 0 12px;
    }
    .p-title {
      text-align: center;
      margin-top: 100px;
      color: #000;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 40px;
    }
    .load-more {
      height: 48px;
      margin: 50px 0;
      .icon {
        cursor: pointer;
        height: 48px;
        width: 120px;
        margin: 0 auto;
        background: url('http://www.pekhongyuan.com/pic/hongyuan/images/061414.png') no-repeat;
        background-position: center center;
        background-size: 100%;
      }
    }
  }
  @media screen and(max-width:900px){
    .flex-btwn{
      display:block;
    }
    .part-item{
      width:90%;
      margin:0 auto 15px;

    }
  }
</style>
