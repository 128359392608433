<template>
  <div>
    <div class="mainWarpPadd50">
      <div class="title">
        <img
          src="http://www.pekhongyuan.com/pic/hongyuan/images/title-bussiness1.png"
          alt="口岸物流"
        />
      </div>
      <div class="instructions">
        <p>
          宏远自2003年开始提供口岸物流服务，随着20多年的发展，宏远口岸物流业务形成了一套专业性强、服务标准一流、工作效率高的服务体系。口岸物流服务也从初期的北京口岸，成功地复制到了南昌、石家庄、厦门等地，由点及面，逐步形成口岸物流服务网络。
        </p>
        <p>
          北京口岸物流服务在宏远整体口岸物流服务中发展的最早，同时也最为成熟。宏远在北京天竺综合保税区内运营进口二级库与出口拼装库，其中，进口二级库占地面积80000平方米，库房面积50000平方米，在库代理共计80余家，700余位工作人员；出口拼装库占地总面积约为46000平方米，其中海关查验区1500平方米，库内工作人员约400人。宏远口岸物流业务年操作货量在北京地区约占航空货运进出口的50%，并为北京口岸近95%以上的航空公司提供出口拼装服务。
        </p>
      </div>
      <div class="mb-100">
        <ul>
          <li>
            <p class="f1">进口分拨业务</p>
            <p class="f2">
              宏远为驻库代理客户提供航班抵港后货物从一级航站至二级库区的分拨服务。
            </p>
          </li>
          <li>
            <p class="f1">出口拼装业务</p>
            <p class="f2">
              宏远出口拼装库为驻库代理
              的空运出口货物提供安检、过重、装箱装板、集装器的管理、货物交付等服务。
            </p>
          </li>
          <li>
            <p class="f1">卡车航班地面操作业务</p>
            <p class="f2">
              配合陆运卡车航班业务，负责卡车航班承运公司进口卡车航班进出京货物的地面操作业务。
            </p>
          </li>
          <li>
            <p class="f1">库房操作与代操作业务</p>
            <p class="f2">
              宏远为驻库代理客户提供多种进口货物库房操作、仓储服务。库房操作集中库操作、共享代码库操作、特殊品类货物操作业务。除库房操作业务外，宏远提供多种的代操作服务，包括整合库代操作、公共服务代操作、内部代操作、特殊货物操作存储与海关及检疫查验中心操作等业务。
            </p>
          </li>
          <li>
            <p class="f1">叉车充电间</p>
            <p class="f2">
              叉车充电间
              宏远提前布局，早在2017年就于库内建造了1000平米的叉车充电间作为配套设施服务。叉车充电间共设置200个充电位，具备防火、防爆结构，配备可燃气体探测器、强排风系统及完善的消防安全系统。
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="bgImgRow">
      <div class="partner">
        <carousel-3d
          :display="3"
          :border="0"
          width="609"
          height="432"
          controlsVisible
          autoplay
          v-if="formData.length > 0"
        >
          <slide v-for="(item, index) in formData" :key="index" :index="index">
            <img :src="item.imageUrl" />
          </slide>
        </carousel-3d>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
import { getPortLogisticsInfo } from '@/api/groupBusiness'
export default {
  components: {
    Carousel3d,
    Slide
  },
  data () {
    return {
      formData: []
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      const data = await getPortLogisticsInfo()
      if (data.code === 200) {
        this.formData = data.data
      }
    }
  }
}
</script>
<style lang="less" scoped>
p,
dl {
  margin-bottom: 0;
}
.title {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}
.instructions {
  font-size: 14px;
  color: #555;
  line-height: 2;
  p {
    margin-bottom: 70px;
  }
}
li {
  padding: 25px 60px;
  list-style: none;
  line-height: 2;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background-color: #d2d2d2;
    position: absolute;
    left: 30px;
    top: 45px;
  }
  .f1 {
    margin-bottom: 5px;
    font-size: 16px;
    color: #333;
  }
  .f2 {
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
  }
}
.bgImgRow {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.partner {
  width: 100%;
  // max-width:1525px;
  // min-width: 1200px;
  padding: 0 70px 40px;
  position: relative;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .bgImgRow {
    // display: none;
    height: auto;
  }
  .mainWarpPadd50 {
    padding: 0 15px;
  }
  .title {
    overflow: hidden;
  }
  .title img {
    width: 100%;
    transform: scale(2.4);
  }
  li {
    padding: 8px 0 20px 30px;

    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: #d2d2d2;
      position: absolute;
      left: 5px;
      top: 18px;
    }
  }

  .partner {
    padding: 0;
    width: 90%;
  }
}
// 轮播
</style>
<style lang="less">
@media screen and(max-width:900px) {
  .next,
  .prev {
    display: none;
  }
}
</style>
