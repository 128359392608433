<template>
  <div class="steps">
    <BoxItem v-for="(item, index) in formData"
      :key="index"
      :lists="item.developmentHistory"
      :type="(index+1)%2 == 1 ? 0 : 1"
      :index="index"
    />
  </div>
</template>
<script>
import BoxItem from './box-item'
// import { data } from './data.json'
export default {
  components: {
    BoxItem
  },
  props: {
    formData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="less" scoped>
  .steps {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 60px;
    padding-top: 20px;
    &:after {
      position: absolute;
      content: " ";
      left: 50%;
      top: 25px;
      bottom: 0px;
      width: 2px;
      background: #254c9b;
    }
  }
  @media screen and(max-width:1200px){
    .steps{width:100%;}
  }
</style>
