export const data = [{
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061417.png',
  time: '2021-05',
  summary: '天竺海关党支部与空港宏远党支部开展共建活动'
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061416.png',
  time: '2020-06',
  summary: '参与服务保障冬奥会和高质量发展专项工作'
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061415.png',
  time: '2020-05',
  summary: '宏远控股集团党总支代表与南昌昌北机场海关监管一科党支部、安检护卫部货检室党支部、地服公司货运保障部党支部，赴方志敏烈士陵园举行支部共建活动'
}]

export const others = [{
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061418.png',
  time: '2021-02',
  summary: '企业与政府对在疫情期间遭遇困哪或作出杰出贡献的医护、干部、群众、家庭开展慰问活动'
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061419.png',
  time: '2019-07',
  summary: '参观学习延庆平北抗日战争纪念馆'
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061420.png',
  time: '2019-03',
  summary: '参观学习中国抗日战争纪念馆主题党日活动'
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061421.png',
  time: '2019-12',
  summary: '召开年度党建工作总结会议'
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061422.png',
  time: '2019-10',
  summary: '宏远集团党支部、工会开展慰问走访活动'
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061423.png',
  time: '2019-03',
  summary: '参观学习“真理的力量.纪念马克思诞辰200周年主题展览”'
}]
