<template>
  <div class="search-item">
    <div class="title" v-html="item.title"></div>
    <div class="sub" v-html="item.summry"></div>
    <div class="link">
      <a @click="goNew(item)">页面链接</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    goNew (item) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          type: item.type,
          pageId: item.id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .search-item {
    padding-top: 10px;
    border-bottom: 1px solid #eee;
    .title {
      font-size: 18px;
      padding-bottom: 10px;
    }
    .sub {
      font-size: 16px;
      padding-bottom: 5px;
    }
    .link {
      font-size: 14px;
      padding-bottom: 5px;
    }
  }
</style>
