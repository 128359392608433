<template>
  <div class="main" id="mains">
    <MainHeader />
    <Banner :bgImg="currentBg" :TopTitle="TopTitle" :secondTitle="secondTitle">
      <div class="banner-item w1200">
        <IntroTitle />
        <!-- <img v-else src="http://www.pekhongyuan.com/pic/hongyuan/images/about.png" /> -->
      </div>
    </Banner>
    <Menus
      :menus="menus"
      class="fix-menus"
      :current="current"
      @select="handleSelect"
    />
    <div class="content">
      <Introduce v-if="current === '1'" />
      <Grow v-if="current === '2'" />
      <Culture v-if="current === '3'" />
      <Core v-if="current === '4'" />
      <WinterPic v-if="current === '5'" />
      <PartWork v-if="current === '6'" />
    </div>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import Menus from '../public/h-menus2'
import { data as menus } from './menus.json'

import Introduce from './components/introduce'
import Grow from './components/grow'
import Culture from './components/culture'
import Core from './components/core'
import WinterPic from './components/winterpic'
import PartWork from './components/part-work'
import IntroTitle from './components/introduce/title'
import { getBannerById } from '@/api/system'

export default {
  data () {
    return {
      bgImg: 'http://www.pekhongyuan.com/pic/hongyuan/images/a1.png',
      current: '1',
      menus,
      TopTitle: '关于我们',
      secondTitle: 'ABOUT US',
      currentBg: 'http://www.lndfwljt.com/image/2021/07/09/060501.jpg'
    }
  },
  watch: {
    '$route.params': {
      handler (params) {
        if (params.type) {
          this.current = params.type
        }
      },
      deep: true
    }
  },
  created () {
    this.current = this.$route.params.type

    this.$nextTick(() => {
      window.scrollTo(0, 0)
    })
    this.getTopPicture()
  },
  methods: {
    handleSelect (v) {
      // console.log(mins.scrollTop)
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
      if (v === this.current) return
      this.$router.push({
        name: 'About',
        params: { type: v }
      })
    },
    async getTopPicture () {
      const data = await getBannerById({ bannerId: 1 })
      if (data.code === 200) {
        this.currentBg = data.data.imageUrl
      }
    }
  },
  components: {
    MainFooter,
    MainHeader,
    Banner,
    Menus,
    Introduce,
    Grow,
    Culture,
    Core,
    WinterPic,
    PartWork,
    IntroTitle
  }
}
</script>
<style lang="less" scoped>
.banner-item {
  margin: 0 auto;
}

.fix-menus {
  margin-top: -80px;
  position: relative;
  z-index: 100;
}

@media screen and (max-width: 900px) {
  .content {
    overflow: hidden;
  }

  .fix-menus {
    margin-top: -40px;
  }
}
</style>
