<template>
  <div class="container flex-btwn">
    <div class="article-main">
      <div class="article-title">
        <p>{{ article.title }}</p>
        <p class="date">发布日期：{{ article.createTime }}</p>
      </div>
      <div class="content" v-html="article.html" />
    </div>
    <ArticleSide />
  </div>
</template>
<script>
import ArticleSide from './article-side'
import { fetchNewDetail } from '@/api/news'
export default {
  components: { ArticleSide },
  data () {
    return {
      article: {
        title: '北京2022年冬奥会祝物流中心建设进入全面收尾阶段',
        date: '2021-05-13',
        content: ''
      }
    }
  },
  mounted () {
    this.getDetail()
  },
  watch: {
    '$route.params': {
      handler (params) {
        this.getDetail()
      },
      deep: true
    }
  },
  methods: {
    async getDetail () {
      const { type, pageId } = this.$route.params

      const data = await fetchNewDetail({ newsType: type, newsId: pageId })
      if (data.code === 200) {
        this.article = data.data
      }
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 1200px;
  margin: auto;
  padding: 50px;
  box-sizing: border-box;
}
.article-main {
  padding-right: 60px;
  padding-bottom: 100px;
}
.article-title {
  margin-bottom: 30px;
  font-size: 28px;
  color: #000;
  line-height: 2;
  border-bottom: 1px solid #ddd;
  .date {
    font-size: 20px;
    color: #555;
  }
  p {
    margin-bottom: 20px;
  }
}
.content {
  margin-bottom: 60px;
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  width: 640px;
  height: auto;
  p {
    margin-bottom: 20px;
    font-size: 14px;
  }
}
@media screen and(max-width:900px) {
  .container {
    width: 100%;
    padding: 15px;
    overflow: hidden;
  }
  .flex-btwn {
    display: block;
  }
  .contact-side {
    width: 100%;
  }
  .article-main {
    padding-right: 0;
  }
  .content {
    width: 100%;
  }
  .content p img {
    width: 100% !important;
    height: 360px;
  }
  .article-title p {
    font-size: 18px;
  }
  .article-title .date {
    font-size: 14px;
  }
}
</style>

<style>
.content p img {
  width: 640px;
  height: 360px;
}

@media screen and (max-width: 900px) {
  .content p img {
    width: 100%;
    height: auto;
  }
}
</style>
