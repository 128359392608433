export const data = [{
  value: '1',
  name: '公司介绍'
}, {
  value: '2',
  name: '发展历程'
}, {
  value: '3',
  name: '企业文化'
}
]
