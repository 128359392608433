<template>
  <div>
    <Article v-if="showArticle" />
    <div v-show="!showArticle">
      <div class="title">
        <img
          src="http://www.pekhongyuan.com/pic/hongyuan/images/title-news1.png"
          alt="公司动态"
        />
      </div>
      <NewsSwiper :detail="newsOutline" :type="type" />
      <div class="mainWarp news-list">
        <ul>
          <NewsItem
            :detail="item"
            v-for="(item, index) in newList"
            :key="index"
          />
        </ul>
        <div class="loadingList" v-show="more" @click="loadingList(pageIndex)">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/ic-arrow-b.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NewsItem from './news-item'
import NewsSwiper from './news-swiper'
import { fetchMainNewLists } from '@/api/news'
export default {
  props: ['detail'],
  components: { NewsItem, NewsSwiper },
  data () {
    return {
      showArticle: false,
      pageIndex: 1,
      newList: [],
      newsOutline: {},
      // 下拉是否显示
      more: true,
      // 新闻类型 0是公司动态
      type: 0
    }
  },
  async mounted () {
    const data = await fetchMainNewLists({
      newsType: 0,
      pageNum: 1,
      pageSize: 5,
      newsTop: 0
    })
    if (data.code === 200) {
      this.newList = data.data.records
    }
  },
  methods: {
    async loadingList (pageIndex) {
      this.pageIndex += 1
      const moreList = await fetchMainNewLists({
        newsType: 0,
        pageNum: this.pageIndex,
        pageSize: 5,
        newsTop: 0
      })
      if (moreList.data.records != null && moreList.code === 200) {
        this.newList = this.newList.concat(moreList.data.records)
        console.log(this.newList)
        if (this.pageIndex >= moreList.data.pages) {
          this.more = false
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
p,
dl {
  margin-bottom: 0;
}
.title {
  margin-top: 60px;
  margin-bottom: 80px;
  text-align: center;
}
.news-list {
  padding: 50px;
  box-sizing: border-box;
  li + li {
    border-top: 1px solid #d2d2d2;
  }
}
.loadingList {
  width: 100%;
  padding: 40px 20px;
  text-align: center;
  cursor: pointer;
}
@media screen and (max-width: 900px) {
  .title {
    overflow: hidden;
  }
  .title img {
    width: 100%;
    transform: scale(2.4);
  }
  .news-list {
    padding: 15px;
  }
}
</style>
