<template>
  <div :class="className" @click="handleSelect">
    <a class="text">{{ data.name }}</a>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    current: {
      type: [Number, String]
    }
  },
  computed: {
    className () {
      return [
        'h-menus-item',
        {
          current: this.current === this.data.value
        }
      ]
    }
  },
  methods: {
    handleSelect () {
      this.$emit('select', this.data.value)
    }
  }
}
</script>
<style lang="less" scoped>
.h-menus-item {
  flex: 1;
  text-align: center;
  background: rgba(51, 111, 208, 0.6);
  transition: all 200ms ease-in-out;
  margin-right:2px;
  user-select: none;
  cursor: pointer;
  .text {
    font-size: 20px;
    color: #fff;
    line-height: 80px;
    transition: all 200ms ease-in-out;
  }
  &.current {
    .text {
      color: #336fd0;
      // font-weight: bold;
    }
    background: #fff;
    // border-bottom: 1px solid #666;
  }
}
@media screen and (max-width:900px){
  .h-menus-item  .text{
    font-size:12px;
    line-height:40px;
  }
}
</style>
