<template>
  <div class="main">
    <MainHeader />
    <MainBanner
      :src="bannerSrc"
      :TopTitle="TopTitle"
      :secondTitle="secondTitle"
    />
    <div class="mainWarp">
      <div class="title">
        <img
          src="http://www.pekhongyuan.com/pic/hongyuan/images/title-social.png"
          alt="可持续性发展"
        />
      </div>
      <p class="instructions" v-if="formData[0]">
        {{ formData[0].subtitleInfo }}
      </p>
    </div>
    <div class="listBg">
      <div class="list row-r" v-if="formData[1]">
        <img
          class="right"
          :src="formData[1].subtitleBackground"
          v-if="formData[1].subtitleBackground"
        />
        <div class="flex-btwn list-text">
          <div class="list-title li-title1">
            <div>
              <p class="f1" v-if="formData[1].name">{{ formData[1].name }}</p>
              <p class="f2" v-if="formData[1].englishName">
                {{ formData[1].englishName }}
              </p>
            </div>
          </div>
          <div class="detail">
            <p class="tit" v-if="formData[1].subtitle">
              {{ formData[1].subtitle }}
            </p>
            <p class="content" v-if="formData[1]">
              {{ formData[1].subtitleInfo }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="listBg" v-if="clientWidth > 900">
      <div class="list row-l">
        <img
          class="left"
          :src="formData[2].subtitleBackground"
          v-if="formData[2].subtitleBackground"
        />
        <div class="flex-btwn list-text">
          <div class="detail">
            <p class="tit" v-if="formData[2].subtitle">
              {{ formData[2].subtitle }}
            </p>
            <p class="content" v-if="formData[2]">
              {{ formData[2].subtitleInfo }}
            </p>
          </div>
          <div class="list-title li-title2 flex-column">
            <p class="f1" v-if="formData[2].name">{{ formData[2].name }}</p>
            <p class="f2" v-if="formData[2].englishName">
              {{ formData[2].englishName }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="listBg" v-if="clientWidth <= 900">
      <div class="list row-l" v-if="formData[2]">
        <div class="list-title li-title2 flex-column">
          <p class="f1" v-if="formData[2].name">{{ formData[2].name }}</p>
          <p class="f2" v-if="formData[2].englishName">
            {{ formData[2].englishName }}
          </p>
        </div>
        <img
          class="left"
          :src="formData[2].subtitleBackground"
          v-if="formData[2].subtitleBackground"
        />
        <div class="flex-btwn list-text">
          <div class="detail">
            <p class="tit" v-if="formData[2].subtitle">
              {{ formData[2].subtitle }}
            </p>
            <p class="content" v-if="formData[2]">
              {{ formData[2].subtitleInfo }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="listBg" v-if="clientWidth <= 900">
      <div class="list row-l">
        <div class="list-title li-title2 flex-column">
          <p class="f1">扶贫助学</p>
          <p class="f2">Poverty Alleviation</p>
        </div>
        <img
          class="left"
          src="http://www.pekhongyuan.com/pic/hongyuan/images/social-12.jpg"
        />
        <div class="flex-btwn list-text">
          <div class="detail">
            <p class="tit">助燃希望，守护梦想</p>
            <p class="content">
              孩子是祖国未来的希望，守护孩子的梦想，是国家可持续发展的根本。自2014年以来，宏远便持续参与贫困助学活动，以各种形式关爱孩子，助燃希望。2020年，宏远更是把冬奥的精神带给了内蒙古巴林左旗的孩子们，让孩子们一起感受体育的力量，奥运的力量。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="listBg">
      <div class="list row-l" v-if="formData[3]">
        <img
          class="right"
          :src="formData[3].subtitleBackground"
          v-if="formData[3].subtitleBackground"
        />
        <div class="flex-btwn list-text">
          <div class="list-title li-title3 flex-column">
            <p class="f1" v-if="formData[3].name">{{ formData[3].name }}</p>
            <p class="f2" v-if="formData[3].englishName">
              {{ formData[3].englishName }}
            </p>
          </div>
          <div class="detail">
            <p class="tit" v-if="formData[3].subtitle">
              {{ formData[3].subtitle }}
            </p>
            <p class="content" v-if="formData[3]">
              {{ formData[3].subtitleInfo }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import MainBanner from '../public/simpleBanner'
import { getBannerById } from '@/api/system'
import { socialResponsibility } from '@/api/socialResponsibility'
// import bannerImg from 'http://www.pekhongyuan.com/pic/hongyuan/images/ressponsibility.jpg'

export default {
  data () {
    return {
      bannerSrc: '',
      TopTitle: '社会责任',
      secondTitle: 'SOCIAL RESPONSIBILITY',
      clientWidth: document.documentElement.clientWidth,
      formData: []
    }
  },
  watch: {
    '$route.params': {
      handler (params) {
        console.log('$route.params')
        console.log(params)
        if (params.type) {
          this.current = params.type
        }
      },
      deep: true
    }
  },
  created () {
    this.current = this.$route.params.type
    this.getBanner()
    this.getInfo()
  },
  methods: {
    handleSelect (v) {
      if (v === this.current) return
      this.current = v
      this.$router.push({
        name: 'Social',
        params: { type: v }
      })
    },
    async getBanner () {
      const data = await getBannerById({ bannerId: 14 })
      if (data.code === 200) {
        this.bannerSrc = data.data.imageUrl
      }
    },
    async getInfo () {
      const data = await socialResponsibility()
      if (data.code === 200) {
        this.formData = data.data
        console.log(this.formData)
      }
    }
  },
  components: {
    MainFooter,
    MainHeader,
    MainBanner
    // Menus,
  }
}
</script>
<style lang="less" scoped>
.title {
  margin-top: 90px;
  margin-bottom: 60px;
  text-align: center;
}

.instructions {
  padding: 0 60px 123px;
  font-size: 14px;
  color: #555;
  line-height: 2;
  text-align: center;
  text-align: center;
}

.listBg {
  width: 100%;
  height: 690px;
  min-width: 1200px;
  margin-bottom: 100px;
  background: url('http://www.pekhongyuan.com/pic/hongyuan/images/bg2.jpg')
    no-repeat center top;
  background-size: auto 100%;
}

.list {
  width: 100%;
  height: 100%;
  max-width: 1523px;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0 60px;
  position: relative;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */

  img {
    width: 65.5%;
    height: auto;
    opacity: 0.15;
    background-color: #fff;
    position: absolute;
  }

  .right {
    right: 60px;
  }

  .left {
    left: 60px;
  }

  .list-text {
    align-items: center;

    & > * {
      width: 50%;
    }
  }

  .list-title {
    height: 300px;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    position: relative;

    .f1 {
      font-size: 36px;
    }

    .f2 {
      font-size: 24px;
    }

    &.li-title1 {
      background-color: #326fba;
    }

    &.li-title2 {
      background-color: #f8da78;
    }

    &.li-title3 {
      background-color: #53ad5b;
    }
  }
}

.detail {
  padding: 50px;

  .tit {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .content {
    font-size: 14px;
    color: #555;
    line-height: 2;
  }
}

.fix-menus {
  margin-top: -90px;
}

@media screen and(max-width: 900px) {
  .title {
    overflow: hidden;
  }
  .title img {
    width: 100%;
    transform: scale(2.4);
  }

  .listBg {
    height: auto;
  }

  .listBg,
  .list {
    min-width: auto;
  }

  .list {
    display: block;
    padding: 0;
  }

  .list .list-text {
    & > * {
      width: 90%;
      margin: 0 auto;
    }
  }

  .list .list-title {
    height: 180px;
  }

  .list img {
    display: none;
  }

  .flex-btwn {
    display: block;
  }

  .instructions {
    padding: 0 15px 30px;
  }

  .detail {
    padding: 30px 0;
  }
}
</style>
