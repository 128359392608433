<template>
  <div class="main">
    <MainHeader />
    <Banner :bgImg="bgImg" :TopTitle="TopTitle" :secondTitle="secondTitle">
      <div class="banner-item w1200 align-center"></div>
    </Banner>
    <!-- 中间内容部分 -->
    <PartTitle :src="tit1" />
    <div class="w1200 part1">
      宏远国际贸易业务以“让世界更近，让生活更美好”为目标，致力于将全球好物引进国内。宏远通过旗下高质量的物流、清报关、保税仓等服务，向国内卖家提供丰富、品质上乘的商品，为国内消费者带来更高品质、更多元化的消费选择。
    </div>
    <!-- part2 -->
    <Box type="style1">
      <div class="part2">
        <h2 class="part2-tit">优势特色</h2>
        <div class="list-container w1200 flex-btwn flex-btwn2">
          <div class="list-item">
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/061437.png"
            />
            <span class="text">原产地直采</span>
          </div>
          <div class="list-item">
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/061436.png"
            />
            <span class="text">自有航空资源</span>
          </div>
          <div class="list-item">
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/061435.png"
            />
            <span class="text">全链条可溯</span>
          </div>
        </div>
      </div>
    </Box>
    <!-- part3 -->
    <div class="w1200 part3">
      <h3>全球采购地</h3>
      <p>欧洲、北美、日本、韩国</p>
      <img
        class="img"
        src="http://www.pekhongyuan.com/pic/hongyuan/images/061434.png"
      />
    </div>
    <!-- part4 -->
    <div class="w1200 part4">
      <h3>跨境商品</h3>
      <div class="container flex-btwn">
        <div class="item">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061441.jpg"
          />
          <span class="text">食品</span>
        </div>
        <div class="item">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061440.jpg"
          />
          <span class="text">日用品</span>
        </div>
        <div class="item">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061439.jpg"
          />
          <span class="text">个护美妆</span>
        </div>
        <div class="item">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061438.jpg"
          />
          <span class="text">奢侈品</span>
        </div>
      </div>
    </div>
    <!-- part5 -->
    <Box type="style1">
      <div class="w1200 part5 flex-btwn">
        <div class="l">
          <a target="_blank" @click="goNew(newsOutline)"
            ><img :src="newsOutline.coverMap"
          /></a>
        </div>
        <div class="r">
          <a target="_blank" @click="goNew(newsOutline)"
            ><h4>{{ newsOutline.title }}</h4></a
          >
          <div class="bd">
            {{ newsOutline.newAbstract || '暂无摘要' }}
          </div>
          <div class="ft">
            <div class="timer">{{ newsOutline.createTime }}</div>
            <a target="_blank" class="none900" @click="goNew(newsOutline)"
              >>更多</a
            >
          </div>
        </div>
      </div>
    </Box>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import PartTitle from '../public/box-title'
import Box from '../public/box'
import { fetchOtherNewLists } from '@/api/news'
import { getBannerById } from '@/api/system'

export default {
  data () {
    return {
      bgImg: '',
      tit1: 'http://www.pekhongyuan.com/pic/hongyuan/images/061439.png',
      newList: [],
      newsOutline: {},
      TopTitle: '国际贸易',
      secondTitle: 'GLOBAL TRADE'
    }
  },
  async mounted () {
    const data = await fetchOtherNewLists({
      pageNum: 1,
      pageSize: 1,
      seatTop: 0,
      seat: 2
    })
    if (data.code === 200) {
      this.newList = data.data.records
      this.newsOutline = this.newList[0]
    }
  },
  created () {
    this.getBanner()
  },
  methods: {
    goNew (item) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          type: item.type,
          pageId: item.id
        }
      })
    },
    async getBanner () {
      const data = await getBannerById({ bannerId: 10 })
      if (data.code === 200) {
        this.bgImg = data.data.imageUrl
      }
    }
  },
  components: {
    MainFooter,
    MainHeader,
    Banner,
    PartTitle,
    Box
  }
}
</script>
<style lang="less" scoped>
.banner-item {
  margin: 0 auto;
}
.part1 {
  line-height: 2.5;
  color: #555;
  margin-bottom: 40px;
  text-align: center;
}
.part2 {
  padding: 40px 0;
  &-tit {
    text-align: center;
    padding-bottom: 40px;
  }
  .list-item {
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    img {
      width: 100%;
    }
    .text {
      color: #fff;
      font-size: 20px;
      letter-spacing: 4px;
      position: absolute;
      left: 50%;
      top: 45%;
      transform: translate(-50%, -50%);
    }
  }
}
.part3 {
  text-align: center;
  // background: #000;
  h3 {
    font-size: 24px;
    letter-spacing: 4px;
  }
  p {
    letter-spacing: 4px;
  }
}
.part4 {
  text-align: center;
  margin-bottom: 80px;
  h3 {
    font-size: 24px;
    letter-spacing: 4px;
    margin-bottom: 40px;
  }
  .item {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    img {
      width: 100%;
    }
    .text {
      color: #fff;
      font-size: 20px;
      letter-spacing: 4px;
      position: absolute;
      left: 50%;
      top: 45%;
      transform: translate(-50%, -50%);
    }
  }
}

.part5 {
  padding-bottom: 40px;
  .l {
    img {
      width: 100%;
    }
  }
  .l,
  .r {
    width: 50%;
  }
  .r {
    padding-left: 20px;
    position: relative;
    h4 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    .bd {
      line-height: 2.5;
    }
    .ft {
      position: absolute;
      padding-left: 20px;
      inset: 0;
      top: auto;
      display: flex;
      justify-content: space-between;
    }
  }
  .img {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .part3 img {
    width: 100%;
  }
  .flex-btwn {
    display: block;
  }
  .part4 .item {
    margin-bottom: 15px;
  }
  .part5 .l {
    width: 100%;
  }
  .part5 .r {
    width: 100%;
    padding: 0;
  }
  .part5 .r .ft {
    position: static;
    padding: 0;
  }
  .none900 {
    display: none;
  }
}
</style>
