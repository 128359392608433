<template>
 <div class="main">
    <div class="main-title">
      <img src="http://www.pekhongyuan.com/pic/hongyuan/images/052007.png" />
    </div>
    <div class="main-sub-title">
      简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍简短的介绍
    </div>

    <div class="bd">
      <Item  v-for="item in 10"
        :key="item"
        :bg="bg"
        :type="item % 2"
      />
    </div>
 </div>
</template>
<script>
import Item from './item'

export default {
  components: {
    Item
  },
  data () {
    return {
      bg: 'http://www.pekhongyuan.com/pic/hongyuan/images/052008.jpg'
    }
  },
  mounted () {
    window.scrollTo(0, 500)
  }
}
</script>
<style lang="less" scoped>
 .main {
    width: 1200px;
    margin: 0 auto;
    &-title {
      img {
        display: block;
        margin: 20px auto;
      }
    }
    &-sub-title {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 20px;
      color: #000;
      text-align: center;
      line-height: 30px;
    }
  }
</style>
