import { Get } from '@/utils/fetch'

export const fetchMainNewLists = ({
  newsType,
  pageNum,
  pageSize,
  newsTop
}) => {
  return Get('/news/getListNews', {
    newsType,
    pageNum,
    pageSize,
    newsTop
  })
}
// 推荐阅读
export const recommended = () => {
  return Get('/news/selectList')
}

export const fetchOtherNewLists = () => {
  return Get('/news/selectInternationalTrade')
}

// 获取新闻详情
export const fetchNewDetail = ({
  newsType,
  newsId
}) => {
  return Get('/news/getNewsInformationByIdAndType', {
    newsType,
    newsId
  })
}
