<template>
  <div class="banner">
    <div class="font72" v-show="TopTitle != null">
      <b>{{ TopTitle }}</b>
    </div>
    <div class="font36">
      <b v-show="secondTitle != null">{{ secondTitle }}</b>
      <br />
      <b v-show="thirdTitle != null">{{ thirdTitle }}</b>
    </div>
    <img :src="src" />
  </div>
</template>
<script>
export default {
  props: ['src', 'TopTitle', 'secondTitle', 'thirdTitle']
}
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  // min-width: 1200px;
  position: relative;
  height: auto;

  img {
    width: 100%;
    // min-width: 1200px;
    z-index: 1;
  }

  div:first-child {
    position: absolute;
    top: 30%;
    left: 11%;
    z-index: 100;
    color: white;
    letter-spacing: 10px;
  }

  div:nth-child(2) {
    position: absolute;
    top: 47%;
    left: 11%;
    z-index: 100;
    color: white;
    letter-spacing: 10px;
  }
  .font72 {
    font-size: 72px;
  }
  .font36 {
    font-size: 36px;
  }
  @media screen and (max-width: 900px) {
    .font72 {
      font-size: 24px;
    }
    .font36 {
      font-size: 16px;
    }
  }
}
</style>
