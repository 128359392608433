<template>
  <div>
    <div class="mainWarpPadd50">
      <div class="title">
        <img
          src="http://www.pekhongyuan.com/pic/hongyuan/images/title-bussiness5.png"
          alt="航空货运"
        />
      </div>
      <div class="instructions">
        <p>
          2018年12月，宏远开通“江西南昌——比利时列日”全货运航线，开展航空干线包机业务。截至目前，宏远已与国航、俄罗斯空桥、摩尔多瓦货运航空等国内外知名航司深度合作，于南昌、厦门、上海和石家庄等地开通了每周近20班次，飞至比利时布鲁塞尔、列日与德国莱比锡等地的中欧全货机航线。
        </p>
        <p>
          为扩展服务网络、提升服务水平，宏远欧洲与JDR、Wallenborn和GEORGI等欧洲当地知名卡车公司紧密合作，目前后程服务网络可覆盖欧洲全部主要城市，其通航点近百个。
        </p>
        <p>
          截至2020年，宏远共运营1180架次全货机航班，货物吞吐量超9万吨，中国大陆至欧洲的运力市场占有率近10%。
        </p>
      </div>
    </div>
    <div class="bgImgRow bgImgRow1">
      <div class="mainWarpPadd50 instructions">
        <div class="flex-btwn" style="padding-bottom:50px;">
          <img
            style="width:45%"
            src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness61.jpg"
          />
          <img
            style="width:45%"
            src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness62.jpg"
          />
        </div>
        <p class="font-layout">
          洲际货运包机业务属于资源、资金、专业门槛均有极高要求的业务模型，凭借在物流行业多年的深耕与积累，宏远集团在航空干线货运包机业务上具备若干天然优势。宏远不仅可为客户提供航空运输的舱位，还可提供由上至下的一站式服务，包括货物交运、关务、操作、运输以及境外的操作、转运等服务，为客户切实减少交接环节、提升效率，给予货物全方位的保障。
        </p>
        <p class="font-layout">
          2021年初，宏远控股集团和江西铁航集团合资成立了江西国际货运航空公司，未来计划通过包机、经营性租赁和融资租赁等方式，打造更加稳定运力，进一步扩展至美国、东南亚等地的航线网络。
        </p>
      </div>
    </div>
    <p class="title2">核心优势</p>
    <div class="bgImgRow bgImgRow2">
      <div class="grid-box">
        <div class="grid-row">
          <div>
            <p class="f1">稳定运力提供坚实时效保障</p>
            <p class="f2">
              自2018年开始运营包机业务，宏远合作多家航司并持续不断的扩充新的运力。即使在疫情严重、全球货机运力告急的情况下，也能够长期保持运力的持续稳定，为合作客户保证足够承诺的舱位与时效，保障优质服务
            </p>
          </div>
          <div>
            <p class="f1">资深地面操作经验为货物保驾护航</p>
            <p class="f2">
              作为北京天竺综合保税区目前唯一具备海关监管进口分拨库、出口拼装区的货物操作企业，宏远集团具备丰富的地面操作经验，拥有专业的操作队伍、齐全的设施设备。每个航点开航前，全部派驻自有操作团队，为货物的安稳出运与分拨提供有力保障。
            </p>
          </div>
          <div>
            <p class="f1">自有卡车航班扩展业务范围</p>
            <p class="f2">
              宏远陆运业务在国内运营多年，服务80余家境内外航司，有丰富的卡车航班运行经验，是航空物流包机业务的出口集货、进口转运等需求的坚实后盾。
            </p>
          </div>
          <div>
            <p class="f1">境外运营团队专业高效</p>
            <p class="f2">
              通过人员派驻、属地招聘等方式，宏远目前在欧洲、日本等地均派驻了自有团，大幅提升了境外反应效率，保障了服务质量。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
p,
dl {
  margin-bottom: 0;
}
.title {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}
.title2 {
  padding-bottom: 60px;
  font-size: 28px;
  color: #555;
  text-align: center;
}
.instructions {
  font-size: 14px;
  color: #555;
  line-height: 2;
  p {
    padding-bottom: 50px;
  }
}
.bgImgRow1 {
  height: auto;
  margin-bottom: 60px;
  background-size: 100% 80%;
  background-position: bottom center;
}
.bgImgRow2 {
  height: auto;
  margin-bottom: 100px;
  background-size: 100% auto;
  background-size: 100% 60%;
  background-position: center center;
}
.li-title {
  padding-top: 90px;
  margin-bottom: 30px;
  font-size: 30px;
  color: #555;
}
.grid-box {
  width: 100%;
  max-width: 1523px;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0 50px;
  box-sizing: border-box;
}
.grid-row {
  width: calc(100% - 40px);
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 30px 40px;
  // grid-column-gap: 40px;
  // grid-row-gap: 70px;
  box-sizing: border-box;
  & > * {
    padding: 50px 30px;
    box-sizing: border-box;
    box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
  .f1 {
    font-size: 16px;
    color: #2f4997;
    line-height: 2;
  }
  .f2 {
    font-size: 14px;
    color: #555;
    line-height: 2.1;
  }
}
@media screen and (max-width: 900px) {
  .mainWarpPadd50 {
    padding: 0 15px;
  }
  .title {
    overflow: hidden;
  }
  .title img {
    width: 100%;
    transform: scale(2.4);
  }
  .bgImgRow1 {
    margin-bottom: 15px;
  }
  .title2 {
    padding-bottom: 15px;
  }
  .grid-box {
    min-width: auto;
    padding: 0 15px;
  }
  .grid-row {
    display: block;
    width: auto;
    & > * {
      margin-bottom: 15px;
    }
  }
}
</style>
