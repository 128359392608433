<template>
  <div class="con-box" :style="bgStyle">
    <div class="con-box-inner">
      <h2>{{ title }}</h2>
      <div v-html="summary"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bg: String,
    title: String,
    summary: String
  },
  computed: {
    bgStyle () {
      return {
        backgroundImage: `url('${this.bg}')`
      }
    }
  }
}
</script>
<style scoped lang="less">
.con-box {
  min-width: 1200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  margin-bottom: 10px;
  height: 400px;
  padding-top: 160px;
  &-inner {
    width: 700px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;
    h2 {
      color: #fff;
    }
  }
}
@media screen and(max-width:900px) {
  .con-box {
    min-width: auto;
    padding: 40px 0;
  }
  .part1 img {
    width: 100%;
  }
  .con-box {
    height: auto;
    min-width: auto;
  }
  .con-box-inner {
    width: 100%;
  }
}
</style>
