var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),(_vm.clientWidth > 768)?[_vm._m(1),_vm._m(2)]:_vm._e(),(_vm.clientWidth <= 768)?[_vm._m(3),_vm._m(4)]:_vm._e(),_vm._m(5)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainWarpPadd50"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/title-bussiness2.png","alt":"保税物流"}})]),_c('div',{staticClass:"instructions"},[_c('p',[_vm._v(" 宏远保税业务始于2007年，经15年保税业务领域经验积累，受到如强生医疗、嘉德、辛克、DHL、康德乐等全球知名企业客户信任。目前，宏远保税业务拥有超50人的专业化服务团队，能为客户提供境外空海运入区/出区、国产品一日游、转口贸易、保税仓储、国际中转、展览展示、电商保税备货、分类监管等多样化服务。 ")])]),_c('p',{staticClass:"f-title"},[_vm._v(" 宏远保税业务年操作量连续15年在北京天竺综合保税区名列前茅。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgImgRow"},[_c('div',{staticClass:"row-main"},[_c('dl',{staticClass:"flex-btwn row-r"},[_c('dt',[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/bussiness31.jpg"}})]),_c('dd',[_c('br'),_c('p',{staticClass:"f1"},[_vm._v("宏远保税库")]),_c('p',[_vm._v(" 宏远保税仓库位于北京天竺综合保税区，是目前区内最大的单体仓储区域。仓库分为仓库和卸货停车区两个部分，其中仓库总面积约为12000平方米，卸货区及停车场约10000平方米。仓库位于独立区域，便于管理和安全保障。在安保方面，保安7x24小时值守，整体库 区配备超过80个监控摄像头，对库内、卸货平台、停车场、办公区等区域进行全方位、无死角的安全管理。 ")]),_c('p',[_vm._v(" 根据海关的监管要求，库内分保税货物、非保税货物、电商类货物；按区域可分：保税区域、非保区域、先入区后申报区域以及电商区域。同时，根据客户的不同，分长期仓储区和短期仓储区、货架区和大货区，各仓储区即可独立运营，也可统一管理。库内，宏远为客户提供报关、进出库、库内分拣、包装、检验、贴标、上下架等多种服务，并可根据客户需求提供如7x24小时出入库服务等定制化服务。 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgImgRow"},[_c('div',{staticClass:"row-main"},[_c('dl',{staticClass:"flex-btwn row-l"},[_c('dd',[_c('p',[_vm._v(" 库内除普通库位外，宏远保税库拥有4个恒温库，恒温库可以实现库内10-25度恒温，湿度30%-70%可调控，同时库内有全方位监控，保障仓储质量。目前恒温库用于存放艺术品、药品、医疗器械、医疗设备、航材等有恒温要求的货物。 ")]),_c('p',[_vm._v(" 库内具有完善的库房管理系统，包括库房配备保税仓储系统、电商备货仓储系统、海关监管平台系统、申报系统等，可以做到即时仓储数量、锁定日期核对仓储数量等数据支持，并能够实现先到先出、指定先出等多种灵活的出入库形式。 ")])]),_c('dt',[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/bussiness32.jpg"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgImgRow"},[_c('div',{staticClass:"row-main"},[_c('dl',{staticClass:"flex-btwn row-r"},[_c('dd',[_c('p',{staticClass:"f1"},[_vm._v("宏远保税库")]),_c('p',[_vm._v(" 宏远保税仓库位于北京天竺综合保税区，是目前区内最大的单体仓储区域。仓库分为仓库和卸货停车区两个部分，其中仓库总面积约为12000平方米，卸货区及停车场约10000平方米。仓库位于独立区域，便于管理和安全保障。在安保方面，保安7x24小时值守，整体库 区配备超过80个监控摄像头，对库内、卸货平台、停车场、办公区等区域进行全方位、无死角的安全管理。 ")]),_c('p',[_vm._v(" 根据海关的监管要求，库内分保税货物、非保税货物、电商类货物；按区域可分：保税区域、非保区域、先入区后申报区域以及电商区域。同时，根据客户的不同，分长期仓储区和短期仓储区、货架区和大货区，各仓储区即可独立运营，也可统一管理。库内，宏远为客户提供报关、进出库、库内分拣、包装、检验、贴标、上下架等多种服务，并可根据客户需求提供如7x24小时出入库服务等定制化服务。 ")])])]),_c('dt',[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/bussiness31.jpg"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgImgRow"},[_c('div',{staticClass:"row-main"},[_c('dl',{staticClass:"flex-btwn row-l"},[_c('dd',[_c('p',[_vm._v(" 库内除普通库位外，宏远保税库拥有4个恒温库，恒温库可以实现库内10-25度恒温，湿度30%-70%可调控，同时库内有全方位监控，保障仓储质量。目前恒温库用于存放艺术品、药品、医疗器械、医疗设备、航材等有恒温要求的货物。 ")]),_c('p',[_vm._v(" 库内具有完善的库房管理系统，包括库房配备保税仓储系统、电商备货仓储系统、海关监管平台系统、申报系统等，可以做到即时仓储数量、锁定日期核对仓储数量等数据支持，并能够实现先到先出、指定先出等多种灵活的出入库形式。 ")])])]),_c('dt',[_c('img',{attrs:{"src":"http://www.pekhongyuan.com/pic/hongyuan/images/bussiness32.jpg"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainWarpPadd50"},[_c('div',{staticClass:"mb-100"},[_c('p',{staticClass:"li-title"},[_vm._v(" 优势特色 ")]),_c('ul',[_c('li',[_c('p',{staticClass:"f1"},[_vm._v("唯一性：")]),_c('p',{staticClass:"f2"},[_vm._v(" 北京天竺综合保税区唯一一家可同时提供分送集报、先入区后申报、分类监管、智能卡口的AEO高级认证物流企业。 ")])]),_c('li',[_c('p',{staticClass:"f1"},[_vm._v("灵活性：")]),_c('p',{staticClass:"f2"},[_vm._v(" 空海铁运输方式随意组合，零进整出、整进零出、简单加工、自操作、代操作等各种仓储方式随意组合。 ")])]),_c('li',[_c('p',{staticClass:"f1"},[_vm._v("低成本：")]),_c('p',{staticClass:"f2"},[_vm._v(" 空海运低于口岸监管区的仓储费用，适合仓储期较长的进口货物。 ")])]),_c('li',[_c('p',{staticClass:"f1"},[_vm._v("专业性：")]),_c('p',{staticClass:"f2"},[_vm._v(" 13000平米区内保税仓储面积，唯一一家拥有4个共计1000平米的综合恒温恒湿库。 ")])])])])])
}]

export { render, staticRenderFns }