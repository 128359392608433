<template>
  <div class="intro-title">
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.intro-title {
  padding-top: 260px;
}
.f1 {
  font-size: 66px;
  margin-bottom: 20px;
  color: #fff;
}
.f2 {
  font-size: 50px;
  color: #fff;
}
</style>
