<template>
  <div class="wrap">
    <img style="width: 100%; display: block" src="@/assets/page.jpg" alt="" />
    <div class="formBox">
      <a-form-model ref="ruleForm" class="formk" :model="form" :rules="rules">
        <a-form-model-item label="" class="fitem">
          <a-input v-model="form.name" placeholder="姓名" />
        </a-form-model-item>
        <a-form-model-item label="" class="fitem" prop="mobileNumber">
          <a-input v-model="form.mobileNumber" placeholder="电话*" />
        </a-form-model-item>
        <a-form-model-item label="" class="fitem" prop="area">
          <a-cascader
            :options="AreaDate"
            v-model="form.area"
            :field-names="{
              label: 'value',
              value: 'value',
              children: 'children',
            }"
            placeholder="开店区域*"
            @change="onChange"
          />
        </a-form-model-item>
        <a-form-model-item label="" class="fitem" prop="budget">
          <a-input v-model="form.budget" placeholder="投资预算*" />
        </a-form-model-item>
      </a-form-model>
      <a-button type="primary" class="btn" @click="submit">
        获取项目资料
      </a-button>
    </div>
  </div>
</template>
<script>
import AreaDate from '@/utils/area.json'
import { joinInfoAdd, getList } from '@/api/home.js'
// import { Carousel3d, Slide } from 'vue-carousel-3d'

export default {
  components: {
    // Carousel3d,
    // Slide
  },
  data () {
    return {
      formData: [],
      AreaDate,
      form: {
        name: '',
        mobileNumber: '',
        area: '',
        budget: '',
        address: ''
      },
      rules: {

        mobileNumber: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        area: [{ required: true, message: '请选择地址', trigger: 'change' }],
        budget: [{ required: true, message: '请输入投资预算', trigger: 'blur' }]

      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      getList({ pageNum: 1, pageSize: 100 }).then(res => {
        console.log(res)
      })
    },
    onChange (e) {
      console.log(e)
    },
    submit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form.address = this.form.area.toString()
          joinInfoAdd(this.form).then(res => {
            if (res.code !== 200) {
              this.$message.info(res.msg)

              return false
            }
            this.$message.info('信息上传成功，请等待管理员联系')
            this.form = {
              name: '',
              mobileNumber: '',
              area: '',
              budget: '',
              address: ''
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  // max-width: 750px;
  margin: 0 auto;
  position: relative;
}
.formBox {
  position: absolute;
  width: 740px;
  height: 290px;
  // background: red;
  left: 935px;
  top: 1198px;
}
.formk {
  padding: 0 30px;
  width: 740px;
  margin: 15px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .fitem {
    width: 300px;
  }
}
.btn {
  margin: 0 auto;
  display: block;
  width: 680px;
  background: #fff;
  color: #09467f;
}
p,
dl {
  margin-bottom: 0;
}

.title {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}

.instructions {
  font-size: 14px;
  color: #555;
  line-height: 2;

  p {
    margin-bottom: 70px;
  }
}

li {
  padding: 25px 60px;
  list-style: none;
  line-height: 2;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background-color: #d2d2d2;
    position: absolute;
    left: 30px;
    top: 45px;
  }

  .f1 {
    margin-bottom: 5px;
    font-size: 16px;
    color: #333;
  }

  .f2 {
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
  }
}

.bgImgRow {
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
}

.partner {
  width: 100%;
  // max-width:1525px;
  // min-width: 1200px;
  padding: 0 70px 40px;
  position: relative;
  box-sizing: border-box;
}

@media screen and (max-width: 900px) {
  .formBox {
    position: static;
    width: 100%;
    height: 276px;
    // background: red;
    left: 0;
    top: 0;
  }
  .formk {
    padding: 0 30px;
    width: 340px;
    margin: 15px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .fitem {
      width: 130px;
    }
  }
  .btn {
    margin: 0 auto;
    display: block;
    width: 280px;
    background: #fff;
    color: #09467f;
  }
  .bgImgRow {
    // display: none;
    height: auto;
  }

  .mainWarpPadd50 {
    padding: 0 15px;
  }

  .title {
    overflow: hidden;
  }

  .title img {
    width: 100%;
    transform: scale(2.4);
  }

  li {
    padding: 8px 0 20px 30px;

    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: #d2d2d2;
      position: absolute;
      left: 5px;
      top: 18px;
    }
  }

  .partner {
    padding: 0;
    width: 90%;
  }
}

// 轮播
</style>
<style lang="less">
@media screen and(max-width:900px) {
  .next,
  .prev {
    display: none;
  }
}
</style>
