<template>
  <div class="main">
    <MainHeader />
    <Banner :bgImg="bgImg" :TopTitle="TopTitle" :secondTitle="secondTitle">
      <div class="banner-item w1200 align-center"></div>
    </Banner>
    <!-- 中间内容部分 -->
    <PartTitle :src="tit1" v-if="clientWidth > 900" />
    <div class="part-title-m" v-if="clientWidth <= 900">
      <img :src="tit1" alt="助力冬奥" />
    </div>
    <div class="w1200 part1">
      <div class="row">
        宏远供应链管理有限公司成立于2015年，公司全体员工将秉持不忘初心、继续前行的信念，积极贯彻集团和投资公司的战略部署，聚焦新目标、新任务，提质增效，致力于城市运营和城市建设相关业务。
      </div>
      <div class="row">
        为更好地建设和服务北京城市副中心发展，2019年8月，宏远供应链管理有限公司被北京城市副中心投资建设集团有限公司全资二级子公司北投投资管理有限公司正式并购，并购后以服务副中心商业贸易的同时，支持相关上下游企业发展。
      </div>
      <div class="row">
        宏远供应链业务内容紧密围绕、支撑北投集团投资建设板块、保障房板块、城市运营板块的定位及目标，不断聚焦副中心发展及百姓生活，提升业务持续性，拓展优质业务。
      </div>
    </div>
    <!-- part2 -->
    <Box type="style1">
      <div class="w1200 part2 container">
        <div class="l w35">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061431.png"
          />
        </div>
        <div class="r w65 pl20">
          <br />

          <h4>城市运营</h4>
          <ul class="list">
            <li>
              加强疏通国内、国际货物流转渠道，优选高质量快速消费品（如3C、食品等），打造高端境内及跨境贸易业务。
            </li>
            <li>
              坚守扶贫攻坚成果，持续推进对口受援地区的定点帮扶，提高贫困地区的特色产品输出。
            </li>
            <li>
              稳定并持续拓展冻肉等农副产品业务，保障高质量农副产品日常需求。
            </li>
            <li>
              逐步推动城市矿产资源等绿色环保经济业务，美化城市环境，协助打造宜居城市。
            </li>
            <li>
              立足北京城市副中心，为落地副中心的企业提供供应链支持，营造良好、具有活力的经商环境。
            </li>
          </ul>
        </div>
      </div>
    </Box>
    <!-- part5 -->
    <Box type="style1">
      <div class="w1200 part3 container">
        <div class="l w65">
          <h4>城市建设</h4>
          <ul class="list">
            <li>
              拓展建筑智慧化、绿色化、高质量化相关建筑产品业务，提高城市建设高质量供应，助力构建坚强韧性、绿色低碳智慧建筑体系。
            </li>
            <li>
              以水泥、钢材、木材、装饰材料等传统建筑材料供应为业务切入点，整合国央企、地产企业供应渠道，聚焦城市建设任务。
            </li>
            <li>
              通过深度合作，解决合作企业源头材料供应问题，促进企业高效运行，助力城市建设。
            </li>
          </ul>
        </div>
        <div class="r w35">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061430.png"
          />
        </div>
      </div>
    </Box>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import PartTitle from '../public/box-title'
import Box from '../public/box'
// eslint-disable-next-line no-unused-vars
import { getBannerById } from '@/api/system'

export default {
  data () {
    return {
      bgImg: 'http://www.pekhongyuan.com/pic/hongyuan/images/061428.jpg',
      tit1: 'http://www.pekhongyuan.com/pic/hongyuan/images/061429.png',
      current: '1',
      TopTitle: '供应链服务',
      secondTitle: 'SUPPLY CHAIN SERVICE',
      clientWidth: document.documentElement.clientWidth
    }
  },
  components: {
    MainFooter,
    MainHeader,
    Banner,
    PartTitle,
    Box
  }
}
</script>
<style lang="less" scoped>
.banner-item {
  margin: 0 auto;
}

.part1 {
  line-height: 2.5;
  color: #555;
  margin-bottom: 40px;

  .row {
    margin-bottom: 40px;
  }

  text-align: center;
}

.w35 {
  width: 35%;
}

.w65 {
  width: 65%;
}

.pl20 {
  padding-left: 20px;
}

.container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;

  img {
    width: 100%;
  }

  h4 {
    font-size: 20px;
  }

  .list {
    li {
      margin-bottom: 20px;
      position: relative;
      padding-left: 20px;

      &::before {
        content: ' ';
        position: absolute;
        left: 10px;
        top: 10px;
        width: 10px;
        height: 10px;
        margin-left: -5px;
        margin-top: -5px;
        background: #d2d2d2;
        border-radius: 50%;
      }
    }
  }
}

@media screen and(max-width: 900px) {
  .container {
    display: block;
  }

  .w65 {
    width: 100%;
    padding: 0;
  }

  .w35 {
    width: 100%;
  }
  .list {
    width: 95%;
    margin: 0 auto;
  }
  .container h4 {
    margin-left: 15px;
  }

  .container h4 {
    margin-left: 15px;
  }
  .part-title-m {
    margin-top: 40px;
    margin-bottom: 80px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
      transform: scale(2.4);
    }
  }
}
</style>
