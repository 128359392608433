<template>
  <div class="h-menus">
    <div class="h-menus-inner">
      <Item
        v-for="(item, index) in menus"
        :data="item"
        :key="index"
        :current="current"
        @select="v => $emit('select', v)"
      />
    </div>
  </div>
</template>
<script>
import Item from './item'

export default {
  props: {
    menus: {
      type: Array,
      default: () => []
    },
    current: {
      type: [Number, String]
    }
  },
  components: {
    Item
  }
}
</script>
<style scoped lang="less">
.h-menus {
  height: 90px;
  background: rgba(255, 255, 255, 0.5);
  &-inner {
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    height: 90px;
    justify-content: space-around;
  }
}
@media screen and(max-width:900px) {
  .h-menus {
    height: 60px;
    &-inner {
      max-width: 1200px;
      display: flex;
      margin: 0 auto;
      height: 60px;
      justify-content: space-around;
    }
  }
}
</style>
