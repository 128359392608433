<template>
  <div class="introduce">
    <div class="part1">
      <div class="w1200">
        <div class="detail">
          <div class="left">
            <img :src="jj" />
            <div class="sub-title">
              <span class="one">Since</span><span class="two">2014</span
              ><span class="three"></span>
            </div>
            <div class="sub-com">
              辽宁五洲通大药房连锁有限公司2014年入驻沈城，是一家迅速崛起的大型医药零售连锁企业。目前，通过直接运营、委托管理、招商加盟三种模式以沈阳为中心在辽宁省内开设零售连锁药店近500余家，会员数量近100万，经营品规9000余个，与东北制药、哈药集团、广药集团等近千家制药厂商建立合作关系。公司致力于追求百姓健康服务，打造民众健康品牌。为了保证产品、服务质量，公司配备了专业药学服务人员，与全国两千余家知名品牌药企建立了合作关系，经营品规达万余种。同时，公司与辽宁中医药大学、沈阳药科大学、沈阳医学院等高等院校建立人才输送渠道，为广大消费者提供了专业、便捷的健康服务平台。
              集团依托产业背景和上下游链条资源搭建医药电商平台、社区一体化医药平台、医药大数据平台、远程审方检测平台，为实现智慧诊疗、智慧医药、智慧医院的有机结合，三甲级医院横向互联互通，三甲到二甲到社区医院纵向互联互通奠定了基础。在智慧诊疗、仓储物流、医药配送、社区药房、居家康养等领域深入开展合作，实现共享资源，创新发展提供广阔的医药市场平台
            </div>
          </div>
          <div class="com-icon">
            <img :src="err" />
          </div>
        </div>
      </div>
    </div>
    <div class="part2">
      <div class="w1200">
        <div class="title">
          <img :src="xzz" />
        </div>
      </div>
    </div>
    <div class="part1">
      <div class="w1200">
        <div class="detail">
          <div class="com-icon" style="margin-right: 30px">
            <img :src="cz1" />
          </div>
          <div class="left">
            <!-- <img src="http://www.pekhongyuan.com/pic/hongyuan/images/i2.png" /> -->
            <div class="sub-title">
              <span class="one">仓储配送中心简介</span>
            </div>
            <div class="sub-com">
              物流中心面积
              1.5万平方米，采用国际领先的机器人分拣技术，引进激光导航无人车(AGV)，被评为沈阳市医药仓储流通标准库房。物流仓储服务平台已经通过京东云仓考察认证，双方达成2000m的战略合作，作为东北地区独家京东医药商品储配中心依托京东平台和云储系统实现智能物流服务。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="title" style="margin: 0 auto">
      <img :src="gyg" style="margin: 0 auto; display: block" />
    </div>
    <div class="part1">
      <div class="w1200">
        <div class="detail">
          <div class="left">
            <!-- <img :src="gyg" /> -->
            <div class="sub-title">
              <span class="one">国医馆简介</span>
            </div>
            <div class="sub-com">
              公司汇聚中国医科大学附属第一医院、辽宁省中医院等中医名师坐诊“国医馆”，定期组织课题交流培训会，免费就诊居民患者，形成以中药医学为纽带的生态社群，为创业者深入研究合作提供医药市场服务。
            </div>
          </div>
          <div class="com-icon">
            <img :src="yg1" />
          </div>
        </div>
      </div>
    </div>
    <!-- <Bg src="http://www.lndfwljt.com/image/2021/07/09/052901.png" :height="600">
      <div class="relative">
        <div class="part3-item first">
          <div class="context">
            <div class="f1">企业愿景</div>
            <div class="f2">CORPORATE VISION</div>
            <div class="f3">
              核心价值观:以客户为中心，以奋斗者为本诚信、包容、敬业、担当<br />

            </div>
          </div>
          <div class="part3-item-overlay" />
        </div>
        <div class="part3-item second">
          <div class="context">
            <div class="f1">企业使命</div>
            <div class="f2">CORPORATE MISSION</div>
            <div class="f3">赋能单体药店，助力财富增长实现创业者一家药店的梦想 </div>
          </div>
          <div class="part3-item-overlay" />
        </div>
      </div>
    </Bg> -->
    <!-- <div class="part4">
      <div class="w1200">
        <div class="title">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/i8.png"
            class="img9"
          />
        </div>
        <div class="container">
          <div
            v-for="(item, index) in formData.honorAndQualificationList"
            :key="index"
            class="item"
          >
            <img :src="item.link" />
            <div class="detail-tit">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
// import Bg from '../../../public/bg'
// import IconItem from './icon-item'
import { ICON } from './icon.json'
import { getCompanyProfileInfo } from '@/api/about'
import cz1 from '@/assets/cz1.jpg'
import yg1 from '@/assets/yg1.jpg'
import err from '@/assets/33.jpg'
import jj from '@/assets/jj.png'
import wsa from '@/assets/wsa.jpg'
import xzz from '@/assets/xzz.png'
import gyg from '@/assets/gyg.png'
export default {
  components: {
    // Bg
    // IconItem
  },
  data () {
    return {
      ICON,
      TOP: true,
      cz1,
      yg1,
      err,
      jj,
      formData: {},
      wsa,
      xzz,
      gyg,
      clientWidth: document.documentElement.clientWidth
    }
  },
  mounted () {
    window.scrollTo(0, 500)
    this.getData()
  },
  methods: {
    async getData () {
      const data = await getCompanyProfileInfo()
      if (data.code === 200) {
        this.formData = data.data
      }
    }
  }
}
</script>
<style lang="less" scoped>
.relative {
  height: 600px;
}
.part1 {
  background: url("http://www.pekhongyuan.com/pic/hongyuan/images/bg2.jpg")
    no-repeat;
  background-position: center center;
  background-color: rgb(243, 247, 250);
  background-size: 100%;
}
.detail {
  display: flex;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  .sub-title {
    margin-bottom: 20px;
    margin-top: 20px;
    .one {
      color: #000;
      font-weight: bold;
      font-style: italic;
      margin-right: 5px;
    }
    .two {
      font-size: 30px;
      color: #336fd0;
      font-style: italic;
      font-weight: bold;
      margin-right: 5px;
    }
    .three {
      color: #000;
      font-weight: bold;
    }
  }
  .sub-com {
    width: 90%;
    line-height: 30px;
    color: #333;
    text-indent: 40px;
    &:hover {
      color: #336fd0;
      cursor: pointer;
    }
  }
  .sub-row {
    margin-top: 20px;
    display: flex;
    .row-item {
      width: 140px;
      .value {
        font-size: 28px;
        color: #336fd0;
        font-weight: bold;
      }
    }
  }
  .left {
    flex: 1;
  }
  .com-icon {
    flex: 1;
  }
}
.com-icon {
  img {
    width: 100%;
  }
}
.part1 {
  padding-bottom: 20px;
}
.part2 {
  .title {
    margin: 0 auto;
    text-align: center;
  }
  .icon {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    padding-top: 40px;
  }
}
.part3-item {
  width: 50%;
  height: 300px;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  cursor: pointer;
  &.first {
    right: 0;
    top: 0;
    .context {
      padding: 60px;
      padding-left: 40px;
    }
  }
  &.second {
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    .context {
      padding: 70px;
      padding-left: 40px;
      min-width: 600px;
      max-width: 760px;
      width: 100%;
    }
  }

  .context {
    color: rgb(11, 69, 149);
    position: relative;
    z-index: 100;
    .f1 {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .f2 {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .f3 {
      font-size: 16px;
    }
  }
  &-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    background: #529fcc;
    z-index: 1;
    transition: 300ms ease-in-out;
  }
  &:hover {
    .part3-item-overlay {
      height: 300px;
    }
    .context {
      color: #fff;
    }
  }
}
.part4 {
  margin-top: 20px;
  .title {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 40px;
  }
  .container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    .item {
      padding: 20px 20px;
      text-align: center;
      width: 30%;
      background: url("http://www.pekhongyuan.com/pic/hongyuan/images/060206.png")
        no-repeat;
      background-size: cover;
      img {
        width: 75%;
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and(max-width:900px) {
  .title {
    overflow: hidden;
  }
  .title img {
    width: 100%;
    transform: scale(2.4);
  }
  .title .img9 {
    width: 100%;
    transform: scale(2.4);
  }
  .left img {
    width: 100%;
    margin: 0 auto;
    // display: block;
  }
  .detail {
    display: block;
    width: 95%;
    margin: 0 auto;
  }
  .detail .sub-com {
    width: 100%;
  }
  .icon-item .image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .part3-item .context {
    .f1 {
      font-size: 18px;
    }
    .f2 {
      font-size: 14px;
    }
    .f3 {
      font-size: 12px;
      // display: none;
    }
  }
  .part3-item.second .context {
    min-width: auto;
  }
  .part4 .container {
    display: block;
    .item {
      width: 80%;
      margin: 0 auto 15px;
    }
  }
  .relative {
    // height: 300px;
  }
}
</style>
