<template>
  <div>
    <PageEnte v-if="page == '0'" />
    <Page1 v-if="page == '1'" />
    <Page2 v-if="page == '2'" />
    <Page3 v-if="page == '3'" />
    <Page4 v-if="page == '4'" />
    <Page5 v-if="page == '5'" />
    <Page6 v-if="page == '6'" />
    <Page7 v-if="page == '7'" />
  </div>
</template>
<script>
import PageEnte from './page-enter'
import Page1 from './page1'
import Page2 from './page2'
import Page3 from './page3'
import Page4 from './page4'
import Page5 from './page5'
import Page6 from './page6'
import Page7 from './page7'
export default {
  props: ['page'],
  components: {
    PageEnte, Page1, Page2, Page3, Page4, Page5, Page6, Page7
  },
  methods: {
    linkTo (v) {
      this.$emit('toArticle', v)
    }
  }
}
</script>
<style lang='less' scoped>
p,
dl {
  margin-bottom: 0;
}
.title {
  padding-top: 30px;
  padding-bottom: 60px;
  text-align: center;
}
.instructions {
  font-size: 14px;
  color: #000;
  text-align: center;
  line-height: 2;
}
</style>
