export const data = [{
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/052305.png',
  title: '以客户为中心',
  lines: [
    '1 为客户创造价值是我们存在的唯一理由；',
    '2 面对客户，首问责任制，绝不推诿； ',
    '3 心怀感恩，尊重客户，保持谦和；',
    '4 洞察客户需求，探索创新机会；',
    '5 与客户建立良好的伙伴关系，共同发展。'
  ],
  hover: {
    title: 'http://www.pekhongyuan.com/pic/hongyuan/images/060707.png',
    bg: 'http://www.pekhongyuan.com/pic/hongyuan/images/060708.png'
  }
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/052306.png',
  title: '以奋斗者为本',
  lines: [
    '1 为火车头加满油，绝不让雷锋吃亏；',
    '2 认真踏实，按时高质量完成本职工作；',
    '3 以结果为导向，不为失败找借口，只为成功找方法；',
    '4 不满足现状，不自我设限，能“平地抠饼”；',
    '5 独立思考，头脑清醒，勇于挑战各种不可能。'
  ],
  hover: {
    title: 'http://www.pekhongyuan.com/pic/hongyuan/images/060705.png',
    bg: 'http://www.pekhongyuan.com/pic/hongyuan/images/060706.png'
  }
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/052307.png',
  title: '拥抱变化',
  lines: [
    '1 面对变化积极学习，充分沟通，全力配合；',
    '2 对变化产生的困难和挫折，能自我调整，并正面影响和带动同事；',
    '3 在工作中有前瞻意识，建立新方法、新思路；',
    '4 创造变化，带来突破性的结果；',
    '5 打破边界，主动补位，坚持做正确的事和正确的做事。'
  ],
  hover: {
    title: 'http://www.pekhongyuan.com/pic/hongyuan/images/060703.png',
    bg: 'http://www.pekhongyuan.com/pic/hongyuan/images/060704.png'
  }
}, {
  src: 'http://www.pekhongyuan.com/pic/hongyuan/images/052308.png',
  title: '协同担当',
  lines: [
    '1 有全局观念，有合作意识，胜则举杯相庆，败则拼死相救；',
    '2 在对待利益矛盾与冲突时，力图寻求相互利益的最佳结合点，实现共赢；',
    '3 有“敢于亮剑”的担当思维，在原则问题上敢于发声；',
    '4 决策前充分表达，决策后坚决执行与配合，绝不阳奉阴违；',
    '5 始终把公司利益置于部门利益之上，把组织利益置于个人利益之上。'
  ],
  hover: {
    title: 'http://www.pekhongyuan.com/pic/hongyuan/images/060701.png',
    bg: 'http://www.pekhongyuan.com/pic/hongyuan/images/060702.png'
  }
}]
