<template>
  <div>
    <div class="mainWarpPadd50">
      <div class="title">
        <img
          src="http://www.pekhongyuan.com/pic/hongyuan/images/title-bussiness4.png"
          alt="卡车航班"
        />
      </div>
      <div class="instructions">
        <p>
          宏远卡车航班物流业务始于2007年，经过14年的发展，业务范围已遍及全国，是目前国内首家全网络空陆联运监管卡车及供应链仓储服务商。
        </p>
      </div>
      <div class="ad mb-100">
        <img
          src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness11.jpg"
        />
      </div>
    </div>
    <div class="bgImgRow">
      <div class="row-main">
        <dl class="flex-btwn row-r">
          <br />
          <dt>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness12.jpg"
            />
          </dt>
          <dd>
            <br />
            <p>
              宏远卡车航班业务目前服务100余家航空公司客户以及其他国内著名企业，并与国有四大航空公司紧密合作，与国航、海航为战略伙伴关系。宏远拥有200余条进出口卡车航班线路，并陆续建立了北京、上海、天津、郑州、南昌、厦门、大连等转运中心，其运输路线覆盖全国各主要城市的卡车航班运输网络，年货运量约为20万吨，可在全国范围为客户提供空陆联运服务。
            </p>
          </dd>
        </dl>
      </div>
    </div>
    <div class="bgImgRow">
      <div class="row-main">
        <dl class="flex-btwn row-l">
          <dd>
            <br />
            <p>
              宏远卡车航班物流所有车辆均经过中国船级社认证，具备海关监管资质，且作为空陆联程运输服务供应商的陆运经营机构，可提供进出口监管运输服务（进口卡车转关，出口一体化报关）以及普通货物公路运输等。
            </p>
            <p>
              宏远卡车航班物流亦是拥有医药认证仓储设施，可为医药、冷链、生鲜等提供个性化转运服务，如全冷链数据追踪转运服务、GPS可视化运输全程追踪服务。
            </p>
          </dd>
          <dt>
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness13.jpg"
            />
          </dt>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      clientWidth: document.documentElement.clientWidth
    }
  }
}
</script>
<style lang="less" scoped>
p,
dl {
  margin-bottom: 0;
}
.title {
  padding-top: 50px;
  padding-bottom: 60px;
  text-align: center;
}
.instructions {
  padding-bottom: 60px;
  font-size: 14px;
  color: #555;
  line-height: 2;
  text-align: center;
}
dl {
  align-items: center;
  dt {
    width: 42%;
  }
  img {
    width: 100%;
  }
  dd {
    width: 58%;
    font-size: 14px;
    color: #555;
    line-height: 2;
    box-sizing: border-box;
    p + p {
      margin-top: 60px;
    }
  }
}
.row-r dd {
  padding-left: 30px;
}
.row-l dd {
  padding-right: 30px;
}
@media screen and (max-width: 900px) {
  .mainWarpPadd50 {
    padding: 0 15px;
  }
  .title {
    overflow: hidden;
  }
  .title img {
    width: 100%;
    transform: scale(2.4);
  }
  .bgImgRow .row-main {
    padding: 0 15px;
    display: block;
  }
  .bgImgRow {
    height: auto;
    padding-bottom: 15px;
  }
  .row-r dd {
    padding: 0;
  }
  dl dd {
    width: auto;
  }
  .row-l dd {
    padding-right: 0;
  }
  .row-main img {
    width: 100%;
  }
  li .f1 {
    margin-bottom: 0;
  }
  .f-title {
    display: flex;
    align-items: center;
    line-height: 100%;
  }
  .flex-btwn {
    display: block;
  }
  dl dt {
    width: auto;
  }
}
</style>
