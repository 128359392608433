<template>
  <div class="main">
    <MainHeader />
    <Banner :bgImg="bgImg" :TopTitle="TopTitle" :secondTitle="secondTitle">
      <div class="banner-item w1200 align-center"></div>
    </Banner>
    <!-- 中间内容部分 -->
    <PartTitle :src="tit1" />
    <div class="w1200 part1">
      <div class="row">
        依托临空经济，宏远于2001年开始进军地产行业，先后开发了宏远·天竺商务园、五矿财富中心、宏远·航城广场、宏远·临空总部港、空港宏远国际货运分拨中心等地标性项目，涵盖5A级写字楼、公寓、商业综合体等多种业态，累计开发面积近百万平方米，取得国家房地产开发企业四级资质。
      </div>
      <div class="row">
        此外，宏远集团还搭建了一只训练有素的物业管理服务团队，以标准化、规范化、制度化服务获得物业管理企业二级资质，管理办公、仓储、商业、酒店等多种业态，合同面积近70万平方米。
      </div>
    </div>
    <!-- part2 -->
    <div class="part2 w1200 flex-btwn" v-if="clientWidth <= 900">
      <div class="big">
        <img src="http://www.pekhongyuan.com/pic/hongyuan/images/061450.jpg" />
        <div class="detail">
          <div class="l">宏远·天竺商务园</div>
        </div>
        <div class="r">
          宏远·天竺商务园一期仓储项目最早于2003年2月建成，经七期工程扩建，最后一期项目于2019年6月竣工。项目位于北京市顺义区临空经济核心区，建筑面积约8.1万平方米，是涵盖写字楼、酒店、仓储库房、餐饮、商铺等功能的多功能、综合性商业建筑群。
        </div>
      </div>
      <div class="big">
        <img src="http://www.pekhongyuan.com/pic/hongyuan/images/061451.jpg" />
        <div class="detail">
          <div class="l">五矿财富中心</div>
        </div>
        <div class="r">
          五矿财富中心于2013年5月接收运营，位于北京市丰台区，项目毗邻北京地铁14号线，交通便利。项目整体建筑面积约0.6万平方米，现建有三层商业办公楼两栋，并涵盖银行、餐饮等多种商业业态。
        </div>
      </div>
      <div class="big">
        <img src="http://www.pekhongyuan.com/pic/hongyuan/images/061452.jpg" />
        <div class="detail">
          <div class="l">宏远·航程广场</div>
        </div>
        <div class="r">
          宏远·航城广场于2014年1月竣工，位于北京市顺义区临空经济核心区，建筑面积约17.3万平方米，是具备多种功能、一站式规划、融合可持续发展理念的空港都市综合体。项目融合5A写字楼、商业、酒店、SOHO办公，等多种城市业态，将商务，商业，住宿等多功能城市空间通过合理的交通连接组合，相互依存、协同促进，形成了多元化的综合空间。
        </div>
      </div>
      <div class="big">
        <img src="http://www.pekhongyuan.com/pic/hongyuan/images/061453.jpg" />
        <div class="detail">
          <div class="l">宏远·临空总部港</div>
        </div>
        <div class="r">
          宏远•临空总部港于2016年9月竣工，位于北京顺义区临空经济核心区。宏远•临空总部港以24栋花园式独栋建筑组成，涵盖写字楼、商办公寓、酒店及配套商业等。项目总用地面积2.1万平方米、总建筑面积9.1万平方米，其中单栋建筑面积800至3100平米，充分满足规模企业的办公服务需求。
        </div>
      </div>
      <div class="big">
        <img src="http://www.pekhongyuan.com/pic/hongyuan/images/061454.jpg" />
        <div class="detail">
          <div class="l">空港宏远<br />国际货运分拨中心</div>
        </div>
        <div class="r">
          空港宏远国际货运分拨中心2021年落成，位于北京天竺综合保税区内，是宏远为2022年北京冬奥会专项打造的重要非竞赛场馆，是家具白电、技术设备、体育器材等办赛物资的集中存储场所。项目采用国际先进的仓储设计理念，总建筑面积达8.1万平方米。项目毗邻机场高速，享有更快捷的通关模式、更便利的交通出行和更低廉的仓储成本，可最大程度保障物资调配的便利性和时效性。
        </div>
      </div>
    </div>
    <div class="part2 w1200 flex-btwn" v-if="clientWidth > 900">
      <div class="item" @mouseenter="enter" @mouseleave="leave">
        <div class="small">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061445.jpg"
          />
          <span class="text">宏远·天竺商务园</span>
        </div>
        <div class="big">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061450.jpg"
          />
          <div class="detail">
            <div class="l">宏远·天竺商务园</div>
            <div class="r">
              宏远·天竺商务园一期仓储项目最早于2003年2月建成，经七期工程扩建，最后一期项目于2019年6月竣工。项目位于北京市顺义区临空经济核心区，建筑面积约8.1万平方米，是涵盖写字楼、酒店、仓储库房、餐饮、商铺等功能的多功能、综合性商业建筑群。
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="small">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061446.jpg"
          />
          <span class="text">五矿财富中心</span>
        </div>
        <div class="big">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061451.jpg"
          />
          <div class="detail">
            <div class="l">五矿财富中心</div>
            <div class="r">
              五矿财富中心于2013年5月接收运营，位于北京市丰台区，项目毗邻北京地铁14号线，交通便利。项目整体建筑面积约0.6万平方米，现建有三层商业办公楼两栋，并涵盖银行、餐饮等多种商业业态。
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="small">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061447.jpg"
          />
          <span class="text">宏远·航程广场</span>
        </div>
        <div class="big">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061452.jpg"
          />
          <div class="detail">
            <div class="l">宏远·航程广场</div>
            <div class="r">
              宏远·航城广场于2014年1月竣工，位于北京市顺义区临空经济核心区，建筑面积约17.3万平方米，是具备多种功能、一站式规划、融合可持续发展理念的空港都市综合体。项目融合5A写字楼、商业、酒店、SOHO办公，等多种城市业态，将商务，商业，住宿等多功能城市空间通过合理的交通连接组合，相互依存、协同促进，形成了多元化的综合空间。
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="small">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061448.jpg"
          />
          <span class="text">宏远·临空总部港</span>
        </div>
        <div class="big">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061453.jpg"
          />
          <div class="detail">
            <div class="l">宏远·临空总部港</div>
            <div class="r">
              宏远•临空总部港于2016年9月竣工，位于北京顺义区临空经济核心区。宏远•临空总部港以24栋花园式独栋建筑组成，涵盖写字楼、商办公寓、酒店及配套商业等。项目总用地面积2.1万平方米、总建筑面积9.1万平方米，其中单栋建筑面积800至3100平米，充分满足规模企业的办公服务需求。
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="small">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061449.jpg"
          />
          <span class="text">空港宏远<br />国际货运分拨中心</span>
        </div>
        <div class="big">
          <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/061454.jpg"
          />
          <div class="detail">
            <div class="l">空港宏远<br />国际货运分拨中心</div>
            <div class="r">
              空港宏远国际货运分拨中心2021年落成，位于北京天竺综合保税区内，是宏远为2022年北京冬奥会专项打造的重要非竞赛场馆，是家具白电、技术设备、体育器材等办赛物资的集中存储场所。项目采用国际先进的仓储设计理念，总建筑面积达8.1万平方米。项目毗邻机场高速，享有更快捷的通关模式、更便利的交通出行和更低廉的仓储成本，可最大程度保障物资调配的便利性和时效性。
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- part3 -->
    <div class="bgImgRow" v-if="clientWidth <= 900">
      <div class="partner">
        <carousel-3d
          :display="3"
          :border="0"
          width="609"
          height="432"
          autoplay
          v-if="slides.length > 0"
        >
          <slide v-for="(item, index) in slides" :key="index" :index="index">
            <img :src="item.imageUrl" />
          </slide>
        </carousel-3d>
      </div>
    </div>
    <div class="part3 w1200" v-if="clientWidth > 900">
      <div class="bd">
        <carousel-3d
          :display="3"
          :height="352"
          :width="500"
          :border="0"
          controlsVisible
          autoplay
          v-if="this.slides.length > 0"
        >
          <slide v-for="(item, index) in slides" :key="index" :index="index">
            <img :src="item.imageUrl" alt="" />
          </slide>
        </carousel-3d>
      </div>
    </div>
    <MainFooter />
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import PartTitle from '../public/box-title'
import { getBannerById } from '@/api/system'
import { getEstateCarouselInfo } from '@/api/groupBusiness'

export default {
  data () {
    return {
      bgImg: '',
      tit1: 'http://www.pekhongyuan.com/pic/hongyuan/images/061452.png',
      current: '1',
      slides: '',
      TopTitle: '临空地产',
      secondTitle: 'PORT REAL ESTATE',
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        }
        // Some Swiper option/callback...
      },
      clientWidth: document.documentElement.clientWidth
    }
  },
  created () {
    this.getBanner()
    this.getEstateCarouselInfo()
  },
  methods: {
    enter () {
      this.visible = true
    },
    leave () {
      this.visible = false
    },
    async getBanner () {
      const data = await getBannerById({ bannerId: 11 })
      if (data.code === 200) {
        this.bgImg = data.data.imageUrl
      }
    },
    async getEstateCarouselInfo () {
      const data = await getEstateCarouselInfo()
      if (data.code === 200) {
        this.slides = data.data
      }
    }
  },
  components: {
    MainFooter,
    MainHeader,
    Banner,
    PartTitle,
    Carousel3d,
    Slide
  },
  directives: {}
}
</script>
<style lang="less" scoped>
.banner-item {
  margin: 0 auto;
}

.part1 {
  line-height: 2.5;
  color: #555;
  margin-bottom: 40px;

  .row {
    margin-bottom: 40px;
  }

  text-align: center;
}

.w35 {
  width: 35%;
}

.w65 {
  width: 65%;
}

.pl20 {
  padding-left: 20px;
}

.container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;

  img {
    width: 100%;
  }

  h4 {
    font-size: 20px;
  }

  .list {
    li {
      margin-bottom: 20px;
      position: relative;
      padding-left: 20px;

      &::before {
        content: ' ';
        position: absolute;
        left: 10px;
        top: 10px;
        width: 10px;
        height: 10px;
        margin-left: -5px;
        margin-top: -5px;
        background: #d2d2d2;
        border-radius: 50%;
      }
    }
  }
}

.part2 {
  margin-bottom: 40px;
  position: relative;

  .item {
    &:hover {
      .big {
        display: block;
      }
    }
  }

  .small {
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
    }

    .text {
      color: #fff;
      position: absolute;
      font-size: 20px;
      left: 50%;
      top: 50%;
      text-align: center;
      white-space: nowrap;
      letter-spacing: 4px;
      transform: translate(-50%, -50%);
    }
  }

  .big {
    display: none;
    position: absolute;
    inset: 0;
    z-index: 2;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
    }

    .detail {
      position: absolute;
      display: flex;
      top: 50%;
      transform: translateY(-50%);
      left: 50px;
      right: 50px;
      align-items: center;

      .l {
        white-space: nowrap;
        letter-spacing: 4px;
        font-size: 20px;
        color: #fff;
        margin-right: 40px;
        text-align: center;
      }

      .r {
        flex: 1;
        color: #fff;
        line-height: 2.5;
        letter-spacing: 4px;
      }
    }
  }
}

.part3 {
  margin-bottom: 40px;
}

@media screen and(max-width: 900px) {
  .partner {
    width: 90%;
    // max-width:1525px;
    // min-width: 1200px;
    padding: 0;
    position: relative;
    box-sizing: border-box;
  }
  .bgImgRow {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    width: auto;
    height: auto;
  }
  .part2 .big {
    display: block;
    position: relative;
    margin: 15px;
    // overflow: hidden;
  }

  .part2 .big .r {
    // display: none;
    margin-top: 15px;
  }

  .part2 .big .detail {
    // margin-top: -108px;
    // position: static;
    transform: none;
    top: 0;
    height: 131px;
  }

  .flex-btwn {
    display: block;

    .item {
      margin-bottom: 15px;
    }
  }
}
</style>
