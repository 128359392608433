import { Get } from '@/utils/fetch'
// data 就是轮博数据
// 集团业务/口岸物流底部轮博
export const getPortLogisticsInfo = () => {
  return Get('/groupBusiness/getPortLogisticsInfo')
}
// 集团业务/凌空地产底部轮博
export const getEstateCarouselInfo = () => {
  return Get('/groupBusiness/getEstateCarouselInfo')
}
