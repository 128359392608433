<template>
  <div class="t-bg" :style="style">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: String
    },
    height: {
      type: Number,
      default: 0
    }
  },
  computed: {
    style () {
      return {
        backgroundImage: `url(${this.src})`,
        height: `${this.height}px`
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .t-bg {
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
</style>
