export const ICON = [{
  tit: 'LOGISTICS',
  subTit: '物流服务',
  icon: 'icon-bg0',
  hicon: 'icon-bg0-hover',
  link: '/business/0'
}, {
  tit: 'TRADE',
  subTit: '国际贸易',
  icon: 'icon-bg1',
  hicon: 'icon-bg1-hover',
  link: '/trade'
}, {
  tit: 'SUPPLY CHAIN',
  subTit: '供应链服务',
  icon: 'icon-bg2',
  hicon: 'icon-bg2-hover',
  link: '/supply-chain'
}, {
  tit: 'REAL ESTATE',
  subTit: '临空地产',
  icon: 'icon-bg3',
  hicon: 'icon-bg3-hover',
  link: '/house-build'
}]
