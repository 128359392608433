import { Get } from '@/utils/fetch'
// 公司介绍
export const getCompanyProfileInfo = () => {
  return Get('/aboutUs/getCompanyProfileInfo', {})
}
// 发展历程 developmentHistoryType 是文字 和内容 developmentHistory 年份详情
export const getDevelopmentHistoryInfo = () => {
  return Get('/aboutUs/getDevelopmentHistoryInfo', {})
}

// 获取助力冬奥底部轮播图 data是list的轮播图
export const getWinterOlympicsInfo = () => {
  return Get('/aboutUs/getWinterOlympicsInfo', {})
}

// 党建工作 分页的
export const getPartyBuildingInfo = ({ pageNum, pageSize }) => {
  return Get('/aboutUs/getPartyBuildingInfo', { pageNum, pageSize })
}
