<template>
  <div class="main">
    <MTitle :src="tit1" />
    <div class="bg">
      <Box type="style1" style="height: 280px; margin-bottom: 280px"></Box>
      <Box type="style1" style="height: 280px; margin-bottom: 280px"></Box>
    </div>
    <Steps :formData="formData" />
  </div>
</template>
<script>
import Box from '../../../public/box'
import Steps from './steps/index'
import MTitle from '../../../public/box-title'
import { getDevelopmentHistoryInfo } from '@/api/about'
import wsa from '@/assets/wsa.jpg'

export default {
  components: {
    Box,
    Steps,
    MTitle
  },
  data () {
    return {
      tit1: wsa,
      formData: []
    }
  },
  mounted () {
    window.scrollTo(0, 500)
    this.getData()
  },
  methods: {
    async getData () {
      const data = await getDevelopmentHistoryInfo()
      if (data.code === 200) {
        this.formData = data.data
      }
    }
  }
}
</script>
<style scoped lang="less">
.main {
  position: relative;
}
.main .bg {
  position: absolute;
  left: 0;
  right: 0;
}
</style>
