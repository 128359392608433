
const weiboAppkey = ''

export const shareWeibo = (artice) => {
  const url = `http://service.weibo.com/share/share.php?url=${encodeURIComponent(artice.link)}&sharesource=weibo&title=${artice.title}&pic=${artice.img}&appkey=${weiboAppkey}`
  return url
}

export const shareQzone = (artice) => {
  return `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(artice.link)}&sharesource=qzone&title=${artice.title}&pics=${artice.img}&summary=${artice.description}`
}
