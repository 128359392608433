<template>
  <div class="main">
    <!-- <PartTitle title="助力冬奥" v-if="clientWidth > 900" :src="tit1" />
    <div class="part-title-m" v-if="clientWidth <= 900">
      <img :src="tit1" alt="助力冬奥" />
    </div>
    <Box type="style1">
      <div class="w1200 part1 flex-btw">
        <div class="left"></div>
        <div class="right">
          <p class="one">
            宏远与奥运结缘于2008年北京奥运会，当时宏远在口岸物流领域提供了服务保障工作，这让宏远与奥运开始有了紧密的联系。
          </p>
          <p class="two">
            2020年6月23日，宏远控股集团旗下的空港宏远与北京冬奥组委正式签约，成为“北京2022年冬奥会和冬残奥会官方仓储设施独家供应商“。为此，宏远专项打造了空港宏远国际货运分拨中心，作为赛会重要的非竞赛场馆之一
            ——<span>北京2022冬奥会和冬残奥会主物流中心。</span>
          </p>
        </div>
      </div>
    </Box> -->
    <!-- part1 end -->
    <!-- part2 -->
    <!-- <PartTitle :src="tit2" /> -->
    <!-- <div class="part2 w1200">
      <div class="title">北京2022年冬奥会主物流中心简介</div>
      <div class="bd">
        <div class="row">
          <div class="over-left">
            <span class="strong">北京2022冬奥会主物流中心(MDC)</span
            >是家具白电、技术设备、体育器材等办赛物资的集中存储场所，同时承担着物资分拨、配送、通关协调及总体配送计划编制等服务保障工作。秉承绿色办奥的理念，为了实现资源的可持续发展，空港宏远国际货运分拨中心在后奥运时代也将继续运营和使用，充分发挥其地缘优势和设计优势，由点及面，促进企业自身成熟完善，同时带动区域环境健康发展，实现经济效益与社会效益的双丰收，成为冬奥的“经济遗产”。
          </div>
          <div class="over-img"></div>
        </div>
        <div class="row ftg">
          空港宏远国际货运分拨中心暨北京冬奥会主物流中心总占地面积4.5万㎡，总建筑面积8.1万㎡，分为南北A、B两座库房，各3万余㎡，库房地上三层，每层均由卸货平台相连接，货运车辆可通过东侧盘道直接到达各层卸货平台，操作便利，为北京2022年冬季奥运会提供高标准物流仓储服务。建筑外墙设计结合了北京2022年冬奥会的核心图形和色彩，顶部喷绘北京2022年冬奥会和冬残奥会会徽，充分体现了其“绿色、共享、开放、廉洁”的愿景。未来将会是首都北京现代化大型仓储的新名片。
        </div>
        <div class="row">
          <div class="over-img2" />
          <div class="over-img2-right">
            空港宏远国际货运分拨中心在设计初期就考虑到了绿色环保和节能减排，在建筑、电气、排水等领域的设计上都充分考虑到了可持续发展的宗旨，最大程度上实现环保。2021年5月，主物流中心还获得了美国LEED绿色环保认证金奖。
          </div>
        </div>
        <div class="row">
          <div class="over-img3-left">
            为了能够更好的保障北京冬奥会的顺利召开，宏远控股集团的4名员工还加入到了主物流中心运行团队的大家庭中，分别在基础设施建设和清废领域担任重要工作，用自己的专业和热情服务冬奥，助力冬奥。
          </div>
          <div class="over-img3" />
        </div>
      </div>
    </div> -->
    <!-- part2 end -->
    <!-- part3 -->
    <!-- <PartTitle :src="tit3" v-if="clientWidth > 900" />
    <div class="part-title-m" v-if="clientWidth <= 900">
      <img :src="tit3" alt="" />
    </div> -->
    <!-- <Box type="style1">
      <div class="bgImgRow" v-if="clientWidth <= 900">
        <div class="partner">
          <carousel-3d
            :display="3"
            :border="0"
            width="609"
            height="432"
            autoplay
            v-if="slides.length > 0"
          >
            <slide v-for="(item, index) in slides" :key="index" :index="index">
              <img :src="item.src" />
            </slide>
          </carousel-3d>
        </div>
      </div>
      <div class="part3 w1200" v-if="clientWidth > 900">
        <div class="bd">
          <carousel-3d
            :height="352"
            :width="500"
            :border="0"
            controlsVisible
            autoplay
          >
            <slide v-for="(item, index) in slides" :key="index" :index="index">
              <img :src="item.src" alt="" />
            </slide>
          </carousel-3d>
        </div>
      </div>
    </Box> -->
    <!-- part3 end -->
  </div>
</template>
<script>
// import { Carousel3d, Slide } from 'vue-carousel-3d'
// import PartTitle from '../../../public/box-title'
// import Box from '../../../public/box'
import { getWinterOlympicsInfo } from '@/api/about'
export default {
  data () {
    return {
      clientWidth: document.documentElement.clientWidth,
      slides: [
        {
          src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061455.jpg'
        },
        {
          src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061456.jpg'
        },
        {
          src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061457.jpg'
        },
        {
          src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061458.jpg'
        },
        {
          src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061909.jpg'
        },
        {
          src: 'http://www.pekhongyuan.com/pic/hongyuan/images/061910.jpg'
        }
      ],
      tit1: 'http://www.pekhongyuan.com/pic/hongyuan/images/052008.png',
      // tit2,
      tit3: 'http://www.pekhongyuan.com/pic/hongyuan/images/061409.png',
      formData: []
    }
  },
  components: {
    // Carousel3d,
    // Slide,
    // PartTitle,
    // Box
  },
  mounted () {
    window.scrollTo(0, 500)
    this.getData()
  },
  methods: {
    async getData () {
      const data = await getWinterOlympicsInfo()
      if (data.code === 200) {
        this.formData = data.data
      }
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  margin: 0 auto;
  .part1 {
    display: flex;
    margin-top: 40px;
    padding-bottom: 40px;
    background-size: 100% 100%;
    .left {
      width: 50%;
      background: url('http://www.pekhongyuan.com/pic/hongyuan/images/061401.jpg')
        no-repeat;
      padding-bottom: 31.83%;
      background-size: 100% 100%;
    }
    .right {
      width: 50%;
      padding: 40px;
      background: rgba(255, 255, 255, 0.4);
      font-size: 16px;
      p {
        color: #000;
        line-height: 2.5;
        margin-bottom: 3em;
        span {
          font-weight: bold;
        }
      }
    }
  }
  .part2 {
    .title {
      font-size: 20px;
      color: #000;
      margin-bottom: 10px;
      position: relative;
      padding-left: 10px;
      font-weight: bold;
      &:before {
        content: ' ';
        position: absolute;
        width: 3px;
        background-color: #336fd0;
        left: 0;
        top: 0;
        bottom: 0;
      }
    }
    .bd {
      margin-top: 20px;
      line-height: 2.5;
      .row {
        display: flex;
        margin-bottom: 20px;
        .over-left {
          width: 50%;
          padding-right: 20px;
        }
        .over-img {
          width: 50%;
          position: relative;
          padding-bottom: 20.6964%;
          background: url('http://www.pekhongyuan.com/pic/hongyuan/images/061404.png')
            no-repeat;
          background-size: 100%;
          &-icon {
            position: absolute;
            width: 100px;
            height: 100px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            top: 50%;
            margin-top: -50px;
            margin-left: -50px;
          }
          &-icon3 {
            background-image: url('http://www.pekhongyuan.com/pic/hongyuan/images/061406.png');
            left: 25%;
          }
          &-icon2 {
            background-image: url('http://www.pekhongyuan.com/pic/hongyuan/images/061407.png');
            left: 50%;
          }
          &-icon1 {
            background-image: url('http://www.pekhongyuan.com/pic/hongyuan/images/061408.png');
            left: 75%;
          }
        }
        .over-img2 {
          width: 150px;
          height: 150px;
          background: url('http://www.pekhongyuan.com/pic/hongyuan/images/061402.png')
            no-repeat;
          background-size: 100%;
          &-right {
            padding-left: 20px;
            display: flex;
            align-items: center;
          }
        }
        .over-img3 {
          width: 40%;
          padding-bottom: 20.565%;
          background: url('http://www.pekhongyuan.com/pic/hongyuan/images/061405.png')
            no-repeat;
          background-size: 100%;
          &-left {
            width: 60%;
            padding-right: 20px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .part3 {
    padding-bottom: 20px;
    /deep/ .carousel-3d-container .carousel-3d-controls .next {
      color: #a6a6a6;
    }
    /deep/ .carousel-3d-container .carousel-3d-controls .prev {
      color: #a6a6a6;
    }
  }
}
@media screen and(max-width:900px) {
  .style1 {
    padding-top: 0;
  }
  .partner {
    width: 90%;
    // max-width:1525px;
    // min-width: 1200px;
    padding: 0;
    position: relative;
    box-sizing: border-box;
  }
  .bgImgRow {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    width: auto;
    height: auto;
  }
  .main .part1 {
    display: block;
    overflow: hidden;
  }
  .left,
  .right {
    float: none;
  }
  .main .part1 .left {
    width: 90%;
    height: 200px;
    margin: 0 auto;
  }
  .main .part1 .right {
    width: 90%;
    margin: 0 auto;
  }
  .main .part2 .bd .row {
    display: block;
  }
  .main .part2 .bd .ftg,
  .main .part2 .bd .row .over-img3-left {
    width: 90%;
    padding-right: 0;
    margin: 0 auto;
  }
  .main .part2 .bd .row .over-left {
    width: 90%;
    padding-right: 0;
    margin: 0 auto;
  }
  .main .part2 .bd .row .over-img,
  .main .part2 .bd .row .over-img3 {
    width: 90%;
    height: 200px;
    margin: 0 auto;
  }
  .main .part2 .bd .row .over-img2 {
    margin: 5px auto;
  }
  .part-title-m {
    margin-top: 40px;
    margin-bottom: 80px;
    text-align: center;
    width: 100%;
    // overflow: hidden;
    img {
      width: 100%;
      transform: scale(2.4);
    }
  }
}
</style>
