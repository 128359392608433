<template>
  <div class="part-title">
    <img :src="src" :alt="title" />
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="less" scoped>
.part-title {
  margin-top: 40px;
  margin-bottom: 80px;
  text-align: center;
}
@media screen and(max-width:900px) {
  .part-title {
    overflow: hidden;
  }
  .part-title img {
    width: 100%;
    transform: scale(2.4);
  }
}
</style>
