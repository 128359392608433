<template>
  <div>
    <div class="mainWarpPadd50">
      <div class="title">
        <img
          src="http://www.pekhongyuan.com/pic/hongyuan/images/title-bussiness0.png"
          alt="物流服务"
        />
      </div>
      <div class="instructions">
        <p>高效可靠的服务、全面完整的业务链条、可复制的商业模式</p>
      </div>
      <div class="grid-box">
        <div class="grid-row">
          <router-link
            :to="{ name: 'Business', params: { type: '1' } }"
            target="_blank"
          >
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness01.jpg"
              alt="口岸物流"
            />
          </router-link>
          <router-link
            :to="{ name: 'Business', params: { type: '2' } }"
            target="_blank"
          >
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness02.jpg"
              alt="保税物流"
            />
          </router-link>
          <router-link
            :to="{ name: 'Business', params: { type: '3' } }"
            target="_blank"
          >
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness03.jpg"
              alt="跨境电商物流"
            />
          </router-link>
          <router-link
            :to="{ name: 'Business', params: { type: '4' } }"
            target="_blank"
          >
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness04.jpg"
              alt="卡车航班"
            />
          </router-link>
          <router-link
            :to="{ name: 'Business', params: { type: '5' } }"
            target="_blank"
          >
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness05.jpg"
              alt="航空货运"
            />
          </router-link>
          <router-link
            :to="{ name: 'Business', params: { type: '6' } }"
            target="_blank"
          >
            <img
              src="http://www.pekhongyuan.com/pic/hongyuan/images/bussiness06.jpg"
              alt="海外仓"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
.title {
  padding-top: 40px;
  padding-bottom: 30px;
  text-align: center;
}
.instructions {
  font-size: 14px;
  color: #000;
  text-align: center;
  line-height: 2;
}
.grid-box {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 110px;
  box-sizing: border-box;
}
.grid-row {
  width: calc(100% - 80px);
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  grid-gap: 70px 40px;
  // grid-column-gap: 40px;
  // grid-row-gap: 70px;
  box-sizing: border-box;
  & > * {
    box-sizing: border-box;
  }
  img {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .mainWarpPadd50 {
    padding: 0 15px;
    min-width: auto;
  }
  .title {
    overflow: hidden;
  }
  .title img {
    width: 100%;
    transform: scale(2.4);
  }
}
</style>
